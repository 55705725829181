import React from "react";
import TableSite from "../../components/admin/TableSite";

function SiteListPage() {
  return (
    // <BasePage>
    //   <Sidebar>
    //   </Sidebar>
    // </BasePage>
    <div className="load-content">
      <TableSite />
    </div>
  );
}

export default SiteListPage;
