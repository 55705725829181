import { SearchOutlined } from "@ant-design/icons";
import React, { useRef, useState, useEffect } from "react";
import Highlighter from "react-highlight-words";
import {
  Button,
  Input,
  Space,
  Table,
  Tag,
  Modal,
  Typography,
  Row,
  Select,
} from "antd";
import moment from "moment";
import { Margin, usePDF } from "react-to-pdf";
import CardPDF from "../shared/CardPDF";
import { ApiClient } from "../../api/ApiClient";

function AdminTableLog({ socketEvent }) {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [siteID, setSiteID] = useState("");
  const [record, setRecord] = useState({});

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPrint, setIsPrint] = useState(false);

  const [dataTable, setDataTable] = useState([]);
  const searchInput = useRef(null);
  const [allSite, setAllSite] = useState([]);

  const { toPDF, targetRef } = usePDF();

  const getSiteList = async () => {
    ApiClient()
      .get(`/sites`)
      .then((res) => {
        var tmp = [];
        res.data.forEach((data) => {
          // console.log(data);
          tmp.push({ label: data.name, value: data.address + "*" + data.sid });
        });
        setAllSite(tmp);
        // console.log(tmp)
        // console.log(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // await axios
    //   .get(`${config.BASEURL}/sites`, config.HEADERS)
    //   .then((res) => {
    //     var tmp = [];
    //     res.data.data.forEach((data) => {
    //       // console.log(data);
    //       tmp.push({ label: data.name, value: data.address + "*" + data.sid });
    //     });
    //     setAllSite(tmp);
    //     // console.log(tmp)
    //     // console.log(res.data.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  const getLog = async (siteID) => {
    ApiClient()
      .get(`/report/trans?field=sid&data=${siteID}`)
      .then((res) => {
        setDataTable(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });

    // await axios
    //   .get(`${config.BASEURL}/report/trans`, config.HEADERS)
    //   .then((res) => {
    //     setDataTable(res.data.data);
    //     setIsLoading(false);
    //     // console.log(res.data.data);
    //     // res.data.data.forEach((data) => {
    //     //   // console.log(data);
    //     //   setDataTable()
    //     //   tmp.push({ label: data.name, value: data.address + "*" + data.sid });
    //     // });
    //     // setAllSite(tmp);
    //     // console.log(res.data.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  const handleSocket = (data) => {
    try {
      const newData = JSON.parse(data);

      // newData.trans_time = moment(newData.trans_time * 1000).format(
      //   "YYYY-MM-DD HH:mm:ss"
      // );
      // console.log(newData);
      if (newData.msg_type === "trans") {
        if (dataTable[0].id === newData.trans.id) {
        } else {
          // console.log(newData.trans.id);
          setDataTable((oldData) => [...oldData, newData.trans]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const hanldePrint = () => {
    setIsPrint(true);
    // setIsModalOpen(false);
    // toPDF({
    //   filename: record.card_uid + "_" + record.trans_time,
    //   page: { margin: Margin.MEDIUM },
    // });
    // setIsModalOpen(false);

    // ReactDOM.render(<PDFView />, document.getElementById("root"));
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#DAFFFB" : "white",
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   key: "id",
    //   width: "100px",
    //   ...getColumnSearchProps("id"),
    //   fixed: "left",
    //   sorter: (a, b) => a.id - b.id,
    //   sortDirections: ["descend", "ascend"],
    //   defaultSortOrder: "descend",
    // },
    {
      title: "Timestamp",
      dataIndex: "trans_time",
      key: "trans_time",
      width: "170px",
      ...getColumnSearchProps("trans_time"),
      fixed: "left",
      sorter: (a, b) => a.id - b.id,
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "descend",
    },
    {
      title: "Card ID",
      dataIndex: "card_uid",
      key: "card_uid",
      width: "120px",
      fixed: "left",
      ...getColumnSearchProps("card_uid"),
    },
    {
      title: "Owner Name",
      dataIndex: "owner_name",
      key: "owner_name",
      fixed: "left",
      width: "200px",
      // align: "center",
      ...getColumnSearchProps("owner_name"),
    },
    {
      title: "License",
      dataIndex: "license",
      key: "license",
      width: "150px",
      // align: "center",
      fixed: "left",
      ...getColumnSearchProps("license"),
    },
    {
      title: "Owner Block Name",
      dataIndex: "owner_block_name",
      key: "owner_block_name",
      width: "200px",
      align: "center",
      // fixed: "left",
      ...getColumnSearchProps("owner_block_name"),
    },
    {
      title: "Owner Block Number",
      dataIndex: "owner_block_number",
      key: "owner_block_number",
      width: "200px",
      align: "center",
      // fixed: "left",
      ...getColumnSearchProps("owner_block_number"),
    },
    {
      title: "Owner Balance",
      dataIndex: "owner_balance",
      key: "owner_balance",
      width: "200px",
      align: "center",
      // fixed: "left",
      ...getColumnSearchProps("owner_balance"),
    },
    {
      title: "Owner Expired Date",
      dataIndex: "owner_expired_date",
      key: "owner_expired_date",
      width: "200px",
      align: "center",
      // fixed: "left",
      ...getColumnSearchProps("owner_expired_date"),
    },
    {
      title: "Site ID",
      dataIndex: "site_id",
      key: "site_id",
      width: "200px",
      align: "center",
      // fixed: "left",
      ...getColumnSearchProps("site_id"),
    },

    {
      title: "Gate ID",
      dataIndex: "gate_id",
      key: "gate_id",
      width: "200px",
      align: "center",
      // fixed: "left",
      ...getColumnSearchProps("gate_id"),
    },

    {
      title: "Method",
      dataIndex: "trans_type",
      key: "trans_type",
      width: "100px",
      fixed: "right",
      align: "center",
      ...getColumnSearchProps("trans_type"),
      render: (_, record) => {
        return (
          <Tag
            color={
              record.trans_type === "OTP"
                ? "gold-inverse"
                : record.trans_type === "QR"
                ? "green-inverse"
                : record.trans_type === "CARD"
                ? "blue-inverse"
                : "red-inverse"
            }
          >
            {record.trans_type}
          </Tag>
        );
      },
    },
    {
      title: "Event",
      dataIndex: "gate_type",
      key: "gate_type",
      width: "100px",
      fixed: "right",
      align: "center",
      ...getColumnSearchProps("gate_type"),
      render: (_, record) => {
        return (
          <Tag
            color={record.gate_type === "IN" ? "blue-inverse" : "red-inverse"}
          >
            {record.gate_type}
          </Tag>
        );
      },
    },
    {
      title: "Image",
      dataIndex: "image_path",
      key: "image_path",
      width: "150px",
      align: "center",
      fixed: "right",

      render: (_, record) => {
        // console.log(record.image_path);
        return (
          <Space size="middle">
            {/* <Button
              // style={{ justifyContent: "center", alignSelf: "center" }}
              onClick={() => {
                // setRecordData(record.im);
                setRecord(record);
                setIsModalOpen(true);
                // console.log(record.image_path);
              }}
            >
              View Image
            </Button> */}
            <button
              className="detail-button"
              onClick={() => {
                // setRecordData(record.im);
                setRecord(record);
                setIsModalOpen(true);
                // console.log(record.image_path);
              }}
            >
              VIEW
            </button>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    // setIsLoading(true);
    getSiteList();
    // getLog();

    return () => {
      // getLog();
      getSiteList();
    };
  }, []);
  useEffect(() => {
    if (socketEvent !== "") {
      handleSocket(socketEvent);
    }
  }, [socketEvent]);

  return (
    <div style={{ padding: "15px" }}>
      <Row align="middle" style={{ paddingBottom: "15px" }}>
        <Typography.Text
          style={{ paddingRight: "10px", fontWeight: "bold", fontSize: "16px" }}
        >
          SELECT SITE :
        </Typography.Text>

        <Select
          options={allSite}
          style={{ width: "20%" }}
          onSelect={(e) => {
            setIsLoading(true);
            setSiteID(e.split("*")[1]);
            // setButtonState(false);
            // getDataCard(e.split("*")[1]);
            getLog(e.split("*")[1]);
          }}
        />
      </Row>

      {/* <Skeleton loading={skeleton} active> */}
      <Table
        columns={columns}
        dataSource={dataTable}
        rowKey={(record) => {
          return record.id;
        }}
        scroll={{
          x: 1300,
        }}
        pagination={{
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} datas`,
          onChange: (e) => {
            // console.log(e);
          },
        }}
        loading={isLoading}
      />
      {/* </Skeleton> */}
      <Modal
        // title="Image"
        open={isModalOpen}
        width={1340}
        align="center"
        onOk={() => {
          setIsModalOpen(false);
        }}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        footer={[
          <Button
            key="print"
            type="primary"
            onClick={() => {
              hanldePrint(record);
            }}
          >
            Print
          </Button>,
          <Button
            key="close"
            onClick={() => {
              setIsModalOpen(false);
            }}
          >
            Close
          </Button>,
          // <Button key="link" href="https://google.com" type="primary">
          //   Search on Google
          // </Button>,
        ]}
      >
        {/* <div ref={targetRef}> */}
        <CardPDF
          data={record}
          isPrint={isPrint}
          setIsPrint={setIsPrint}
          setIsModalOpen={setIsModalOpen}
        />
        {/* </div> */}
        {/* <Image
          src={record.image_path}
          // height={720}
          // width={1280}
          style={{
            height: "100%",
            width: "100%",
          }}
          preview={false}
          fallback={process.env.PUBLIC_URL + "/images/404-error.png"}
        /> */}
      </Modal>
    </div>
  );
}
export default AdminTableLog;
