import {
  SearchOutlined,
  QuestionCircleOutlined,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import React, { useRef, useState, useEffect } from "react";
import Highlighter from "react-highlight-words";
import {
  Button,
  Input,
  Space,
  Table,
  Switch,
  notification,
  Popconfirm,
  Spin,
  Modal,
  Form,
  DatePicker,
} from "antd";
import FormUserEdit from "./FormUserEdit";
import { ApiClient } from "../../api/ApiClient";
import dayjs from "dayjs";
import { read, utils, writeFile } from "xlsx";

function TableCardListUser() {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [unixtime, setUnixtime] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);
  const [isModalUpdateExpired, setIsModalUpdateExpired] = useState(false);

  const [recordData, setRecordData] = useState({});
  const [dataCard, setDataCard] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [form] = Form.useForm();

  const searchInput = useRef(null);

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
    });
  };

  const getDataCard = () => {
    ApiClient()
      .get(
        `/accounts/filter?field=sid&data=${localStorage.getItem(
          "sid"
        )}&detail=1&refresh=true`
      )
      .then((res) => {
        if (res.data) {
          res.data.map((dtCard) => {
            dtCard.human_date = dayjs
              .unix(dtCard.expired_date)
              .format("DD-MM-YYYY");
          });
          // console.log(res.data);
          setDataCard(res.data);
          setIsLoading(false);
          // console.log(dataCard);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // await axios
    //   .get(
    //     `${
    //       config.BASEURL
    //     }/accounts/filter?field=sid&data=${localStorage.getItem(
    //       "sid"
    //     )}&detail=1&refresh=true`,
    //     config.HEADERS
    //   )
    //   .then((res) => {
    //     setDataCard(res.data.data);
    //     setIsLoading(false);
    //     // console.log(res.data.data);
    //     // console.log("Get All", res.data.data[0].CardAccess);
    //   })
    //   .catch((err) => {
    //     console.log(err.message);
    //   });
  };
  const updateCardStatus = async (data) => {
    ApiClient()
      .patch(`/accounts/${data.aid}`, {
        status: Number(!data.CardAccess.status),
      })
      .then((res) => {
        openNotificationWithIcon("success", "Update Access", res.message);

        getDataCard();
        // }, 2000);
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          "Update Access",
          err.response.data.message
        );
      });

    // await axios
    //   .patch(
    //     `${config.BASEURL}/accounts/${data.aid}`,
    //     {
    //       status: Number(!data.CardAccess.status),
    //     },
    //     config.HEADERS
    //   )
    //   .then((res) => {
    //     openNotificationWithIcon("success", "Update Access", res.data.message);

    //     getDataCard();
    //     // }, 2000);
    //   })
    //   .catch((err) => {
    //     openNotificationWithIcon(
    //       "error",
    //       "Update Access",
    //       err.response.data.message
    //     );
    //   });
  };

  const openModal = (record) => {
    var datas = record;
    datas.expired_dates = dayjs.unix(datas.expired_date);
    setRecordData(datas);

    setIsModalOpen(true);
  };

  const deleteCard = async (val) => {
    // await axios.delete(`${config.BASEURL}/cards/${val.cid}`);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#DAFFFB" : "white",
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: "No.",
      width: "50px",
      fixed: "left",
      render: (item, record, index) => <>{index + 1}</>,
    },
    {
      title: "Card ID",
      dataIndex: "username",
      key: "username",
      width: "130px",
      fixed: "left",
      ...getColumnSearchProps("username"),
      sorter: (a, b) => {
        return parseInt(a.username) - parseInt(b.username);
      }, // (a, b) => a.card_uid.length - b.card_uid.length,

      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
    },
    {
      title: "Name",
      dataIndex: "full_name",
      key: "full_name",
      width: "180px",
      fixed: "left",
      ...getColumnSearchProps("full_name"),
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
      width: "150px",
      fixed: "left",
      ...getColumnSearchProps("phone"),
      // sorter: (a, b) => {
      //   return a - b;
      // }, // (a, b) => a.card_uid.length - b.card_uid.length,

      // sortDirections: ["descend", "ascend"],
    },
    {
      title: "License",
      dataIndex: "license",
      key: "license",
      width: "100px",
      // fixed: "left",
      ...getColumnSearchProps("license"),
    },

    // {
    //   title: "E-mail",
    //   dataIndex: "email",
    //   key: "email",
    //   width: "200px",
    //   ...getColumnSearchProps("email"),
    // },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: "400px",
      align: "center",
      ...getColumnSearchProps("address"),
    },
    // {
    //   title: "Block Name",
    //   dataIndex: "block_name",
    //   key: "block_name",
    //   width: "200px",
    //   align: "center",
    //   ...getColumnSearchProps("block_name"),
    // },
    // {
    //   title: "Block Number",
    //   dataIndex: "block_number",
    //   key: "block_number",
    //   width: "200px",
    //   align: "center",
    //   ...getColumnSearchProps("block_number"),
    // },
    // {
    //   title: "Balance",
    //   dataIndex: "balance",
    //   key: "balance",
    //   width: "150px",
    //   // fixed: "right",
    //   align: "center",
    //   ...getColumnSearchProps("balance"),
    // },
    {
      title: "Expired Date",
      dataIndex: "human_date",
      key: "human_date",
      width: "140px",
      // fixed: "right",
      // align: "center",
      ...getColumnSearchProps("human_date"),
      sorter: (a, b) => {
        return a.expired_date - b.expired_date;
      }, // (a, b) => a.card_uid.length - b.card_uid.length,
      fixed: "right",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
    },

    // {
    //   title: "Account Status",
    //   key: "status",
    //   align: "center",
    //   width: "150px",
    //   fixed: "right",
    //   render: (_, record) => {
    //     // console.log(record);
    //     return (
    //       <Space size="middle">
    //         <Switch
    //           disabled={true}
    //           checked={record.status}
    //           onChange={() => {
    //             // updateStatus(record);
    //           }}
    //         />
    //       </Space>
    //     );
    //   },
    // },
    {
      title: "Access Status",
      key: "status",
      align: "center",
      width: "100px",
      fixed: "right",
      render: (_, record) => {
        return (
          <Popconfirm
            title="Update Access"
            description="Are you sure to update this access status?"
            icon={<QuestionCircleOutlined style={{ color: "blue" }} />}
            placement="top"
            // open={popconfirm}
            onConfirm={() => {
              updateCardStatus(record);
            }}
            // onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Space size="middle">
              <Switch
                defaultChecked={record ? record.CardAccess.status : false}
                checked={record ? record.CardAccess.status : false}
              />
            </Space>
          </Popconfirm>
        );
      },
    },
    {
      title: "Edit",
      key: "edit",
      // dataIndex: "edit",
      align: "center",
      width: "100px",
      fixed: "right",
      render: (_, record) => {
        // console.log(record);
        return (
          <Space>
            <button
              className="edit-button"
              onClick={() => {
                // record.expired_date = dayjs.unix(record.expired_date);
                // console.log(record.expired_date);

                openModal(record);
                // console.log(record);
              }}
            >
              EDIT
            </button>
          </Space>
        );
      },
    },
    // {
    //   title: "Delete",
    //   key: "delete",
    //   align: "center",
    //   width: "100px",
    //   fixed: "right",
    //   render: (_, record) => {
    //     // console.log(record);
    //     return (
    //       <Popconfirm>
    //         <Space size="middle">
    //           <Button
    //             danger
    //             onClick={() => {
    //               console.log(record);
    //               // setRecordData(record);
    //               // setIsModalOpen(true);
    //               // console.log(record);
    //             }}
    //           >
    //             Delete
    //           </Button>
    //         </Space>
    //       </Popconfirm>
    //     );
    //   },
    // },
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    // console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  const updateAllAccountAccess = async () => {
    var errCount = 0;
    for (var i = 0; i < selectedRowKeys.length; i++) {
      var error = false;
      await ApiClient()
        .patch(`/accounts/${selectedRowKeys[i]}`, {
          status: 1,
        })
        .then((res) => {
          // }, 2000);
        })
        .catch((err) => {
          // openNotificationWithIcon(
          //   "error",
          //   "Update Access",
          //   "Error Update User"
          // );
          errCount += 1;
          error = true;
        });
      if (error) {
        openNotificationWithIcon("error", "Update Access", "Error Update User");
        break;
      }
    }
    // selectedRowKeys.map((value) => {
    //   ApiClient()
    //     .patch(`/accounts/${value}`, {
    //       status: 1,
    //     })
    //     .then((res) => {
    //       // }, 2000);
    //     })
    //     .catch((err) => {
    //       openNotificationWithIcon(
    //         "error",
    //         "Update Access",
    //         "Error Update User"
    //       );
    //     });
    // });
    openNotificationWithIcon("success", "Update Access", `Done Update All `);

    getDataCard();
  };

  const updateDataUser = async (userData) => {
    var countErr = 0;
    var countSucc = 0;
    var errorUser = [];

    for (let i = 0; i < userData.length; i++) {
      const body = {
        full_name: userData[i].full_name
          ? userData[i].full_name.toString()
          : "",
        address: userData[i].address ? userData[i].address.toString() : "",
        // block_name: account.block_name,
        // block_number: account.block_number,
        // balance: parseInt(account.balance),
        status: 1,
        phone: userData[i].phone ? userData[i].phone.toString() : "",
        license: userData[i].license ? userData[i].license.toString() : "",
        expired_date: dayjs(dayjs().add(3, "M").format("YYYY-MM-DD")).unix(),
      };

      // console.log(userData[i]);

      await ApiClient()
        .patch(`/accounts/${userData[i].aid ? userData[i].aid : ""}`, body)
        .then((res) => {
          countSucc += 1;
          // console.log(res);
        })
        .catch((err) => {
          errorUser.push(userData.username);
          countErr += 1;

          // console.log(err);
        });
    }
    // await userData.map(async (dt) => {

    // });

    if (countErr > 0) {
      openNotificationWithIcon(
        "error",
        "Update User Data",
        `Failed Update ${countErr} User`
      );
      errorUser.forEach((dtu) => {
        openNotificationWithIcon(
          "error",
          "Update User Data",
          `Failed Update Card ID ${dtu}`
        );
      });
    }
    openNotificationWithIcon(
      "success",
      "Update User Data",
      `Success Update ${countSucc} User`
    );

    getDataCard();
    setIsSpinning(false);
  };
  const hasSelected = selectedRowKeys.length > 0;

  const handleSaveExcel = () => {
    // const ws  = utils.json_to_sheet(dataCard)
    // const wb ={Sheets:{'data':ws},SheetNames:['data']}

    const changeKeyLabel = (array, oldKey, newKey) => {
      array.forEach((obj) => {
        if (obj.hasOwnProperty(oldKey)) {
          obj[newKey] = obj[oldKey];
          delete obj[oldKey];
        }
      });
    };

    var tmp = dataCard;
    tmp
      .sort((a, b) => parseInt(a.username) - parseInt(b.username))
      .forEach((dt) => {
        delete dt.ID;
        delete dt.CreatedAt;
        delete dt.UpdatedAt;
        delete dt.DeletedAt;
        delete dt.aid;
        delete dt.rid;
        delete dt.cid;
        delete dt.CardAccess;
        delete dt.expired_date;
      });
    changeKeyLabel(tmp, "human_date", "expired_date");
    changeKeyLabel(tmp, "username", "card_id");

    const wb = utils.book_new();
    const ws = utils.json_to_sheet(tmp);

    // utils.sheet_add_aoa(ws, headings);
    // utils.sheet_add_json(ws, card, { origin: "A2", skipHeader: true });
    utils.book_append_sheet(wb, ws, "Report");
    writeFile(wb, "DATA CARD.xlsx");
  };

  const handleUploadExcel = ($event) => {
    const files = $event.target.files;
    setIsSpinning(true);
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;

        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          // console.log(rows);
          var filteredRows = [];
          rows.forEach((dt) => {
            if (dt.username) {
              // console.log(dt);
              filteredRows.push(dt);
            }
          });

          // console.log(newArray);
          filteredRows.map((item) => {
            dataCard.map((obj) => {
              if (item.username?.toString() !== undefined) {
                if (obj.username === item.username?.toString()) {
                  // console.log(true);
                  item.aid = obj.aid;
                }
              }
            });
          });

          updateDataUser(filteredRows);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleUpdateAllExpired = async () => {
    const body = {
      expired_date: unixtime,
    };
    setIsLoading(true);

    try {
      for (const account of dataCard || []) {
        try {
          const res = await ApiClient().patch(`/accounts/${account.aid}`, body);
          // console.log(res.data.data);
          // notification("success", "Update Account", res.message);
        } catch (err) {
          // notification("error", "Update Account", err.response?.data?.message);
          // console.log(err);
        }
      }

      setIsModalUpdateExpired(false);
      await getDataCard();
    } catch (error) {
      console.error("Error updating accounts:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getDataCard();
  }, []);

  return (
    <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
      {contextHolder}
      <Spin tip="Loading..." spinning={isSpinning} size="large">
        <div
          style={{
            marginBottom: 16,
          }}
        >
          <Space size="large">
            <Button
              className="add-button"
              // type="primary"
              onClick={handleSaveExcel}
              icon={<DownloadOutlined />}
            >
              DOWNLOAD DATA
            </Button>

            <input
              type="file"
              className="add-button"
              accept=".xlsx, .xls, .csv"
              required
              onChange={(e) => {
                handleUploadExcel(e);
                // console.log(e.target);
              }}
            />

            <Button
              className="add-button"
              onClick={() => {
                setIsModalUpdateExpired(true);
              }}
            >
              UPDATE ALL EXPIRED DATE
            </Button>
          </Space>

          {/* <button
          // type="primary"
          className="add-button"
          // disabled={!hasSelected}
          onClick={handleSaveExcel}
          // loading={loading}
        >
          EXPORT DATA USER
        </button>
        <span
          style={{
            marginLeft: 8,
          }}
        >
          {hasSelected ? `Selected ${selectedRowKeys.length} users` : ""}
        </span> */}
        </div>
        <Table
          // rowSelection={rowSelection}
          columns={columns}
          dataSource={dataCard ? dataCard : []}
          pagination={{
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} users`,
          }}
          rowKey={(record) => {
            return record.aid;
          }}
          scroll={{
            x: 1300,
          }}
          loading={isLoading}
          onRow={(record, index) => ({
            style: {
              color:
                record.expired_date >
                dayjs(dayjs().add(7, "days").format("YYYY-MM-DD")).unix()
                  ? "black"
                  : dayjs(dayjs().add(7, "days").format("YYYY-MM-DD")).unix() >=
                      record.expired_date &&
                    dayjs(dayjs().format("YYYY-MM-DD")).unix() <
                      record.expired_date
                  ? "blue"
                  : "red",
              // background:
              //   record.email === "dzulfikar@example.com" ? "red" : "default",
            },
          })}
          // summary={() => {
          //   <Table.Summary fixed="top" />;
          // }}
        />
        <FormUserEdit
          data={recordData}
          getDataCard={getDataCard}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          notification={openNotificationWithIcon}
        />
      </Spin>

      <Modal
        title="Update Expired Date"
        open={isModalUpdateExpired}
        onCancel={() => {
          setIsModalUpdateExpired(false);
        }}
        onOk={handleUpdateAllExpired}
      >
        <Form
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 10,
          }}
          layout="horizontal"
        >
          <Form.Item label="Expired Date" name="expired_dates">
            <DatePicker
              onChange={(date, dateString) => {
                setUnixtime(
                  dayjs(dayjs(dateString).format("YYYY-MM-DD")).unix()
                );
                // console.log(unixtime);
              }}
            />
            {/* <Input /> */}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
export default TableCardListUser;
