import { SearchOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import React, { useRef, useState, useEffect } from "react";
import Highlighter from "react-highlight-words";
import {
  Button,
  Input,
  Row,
  Select,
  Space,
  Table,
  Typography,
  Switch,
  notification,
  Popconfirm,
} from "antd";
import axios from "axios";
import config from "../../config/global";
import FormCardRegistration from "./FormCardRegistration";
import { ApiClient } from "../../api/ApiClient";
import { read, utils, writeFile } from "xlsx";

function TableCardListAdmin() {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [sid, setSid] = useState("");

  const [isModalCardRegistration, setIsModalCardRegistration] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [buttonState, setButtonState] = useState(true);

  const [dataCard, setDataCard] = useState([]);
  const [allSite, setAllSite] = useState([]);

  const [api, contextHolder] = notification.useNotification();

  const searchInput = useRef(null);

  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
    });
  };
  const getSiteList = async () => {
    ApiClient()
      .get(`/sites`)
      .then((res) => {
        var tmp = [];
        // var tmp_admuser = []
        if (localStorage.getItem("credent") == "4") {
          res.data.forEach((data) => {
            // console.log(data);
            tmp.push({
              label: data.name,
              value: data.address + "*" + data.sid,
            });
          });
        } else {
          res.data.forEach((data) => {
            // console.log(data);
            if (data.sid == localStorage.getItem("sid")) {
              tmp.push({
                label: data.name,
                value: data.address + "*" + data.sid,
              });
            }
          });
        }
        setAllSite(tmp);
        // console.log(tmp)
        // console.log(res.data.data);
      })
      .catch((err) => {
        openNotificationWithIcon("error", "Get Data", err.message);
        console.log(err);
      });
    // await axios
    //   .get(`${config.BASEURL}/sites`, config.HEADERS)
    //   .then((res) => {
    //     var tmp = [];
    //     res.data.data.forEach((data) => {
    //       // console.log(data);
    //       tmp.push({ label: data.name, value: data.address + "*" + data.sid });
    //     });
    //     setAllSite(tmp);
    //     // console.log(tmp)
    //     // console.log(res.data.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  const getDataCard = async (sid) => {
    setIsLoading(true);

    ApiClient()
      .get(`/accounts/filter?field=sid&data=${sid}&detail=1&refresh=true`)
      .then((res) => {
        setDataCard(res.data);
        setIsLoading(false);
        // console.log(res.data);
        // console.log(res.data.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
    // await axios
    //   .get(
    //     `${config.BASEURL}/accounts/filter?field=sid&data=${sid}&detail=1&refresh=true`,
    //     config.HEADERS
    //   )
    //   .then((res) => {
    //     setDataCard(res.data.data);
    //     setIsLoading(false);
    //     // console.log(res.data.data);
    //     // console.log(res.data.data);
    //   })
    //   .catch((err) => {
    //     console.log(err.message);
    //   });
  };

  const updateCardStatus = async (data) => {
    // console.log(data);
    ApiClient()
      .patch(`/cards/${data.cid}`, {
        status: Number(!data.CardAccess.status),
      })
      .then((res) => {
        openNotificationWithIcon("success", "Update Card Status", res.message);

        // console.log("Update Response", res.data.data);
        // window.location.reload(true);
        // setTimeout(() => {

        // }, 2000);
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          "Update Card Status",
          err.response.data.message
        );
        console.log(err.message);
      })
      .finally(() => {
        getDataCard(data.CardAccess.sid);
      });
    // await axios
    //   .patch(
    //     `${config.BASEURL}/cards/${data.cid}`,
    //     {
    //       status: Number(!data.CardAccess.status),
    //     },
    //     config.HEADERS
    //   )
    //   .then((res) => {
    //     openNotificationWithIcon(
    //       "success",
    //       "Update Card Status",
    //       res.data.message
    //     );

    //     // console.log("Update Response", res.data.data);
    //     // window.location.reload(true);
    //     // setTimeout(() => {

    //     // }, 2000);
    //   })
    //   .catch((err) => {
    //     openNotificationWithIcon(
    //       "error",
    //       "Update Card Status",
    //       err.response.data.message
    //     );
    //     console.log(err.message);
    //   })
    //   .finally(() => {
    //     getDataCard(data.CardAccess.sid);
    //   });
  };
  const updateAccountStatus = async (data) => {
    ApiClient()
      .patch(`/accounts/${data.aid}`, {
        status: Number(!data.status),
      })
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Update Account Status",
          res.message
        );
        getDataCard(data.CardAccess.sid);

        // console.log(res.data.data);
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          "Update Account Status",
          err.response.data.message
        );
        // console.log(err);
      });
    // await axios
    //   .patch(
    //     `${config.BASEURL}/accounts/${data.aid}`,
    //     {
    //       status: Number(!data.status),
    //     },
    //     config.HEADERS
    //   )
    //   .then((res) => {
    //     openNotificationWithIcon(
    //       "success",
    //       "Update Account Status",
    //       res.data.message
    //     );
    //     getDataCard(data.CardAccess.sid);

    //     // console.log(res.data.data);
    //   })
    //   .catch((err) => {
    //     openNotificationWithIcon(
    //       "error",
    //       "Update Account Status",
    //       err.response.data.message
    //     );
    //     // console.log(err);
    //   });
  };

  const updateMasterCard = async (data) => {
    ApiClient()
      .patch(`/cards/${data.cid}`, {
        type: Number(!data.CardAccess.type),
      })
      .then((res) => {
        openNotificationWithIcon("success", "Update Card Master", res.message);

        // console.log("Update Response", res.data.data);
        // window.location.reload(true);
        // setTimeout(() => {

        // }, 2000);
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          "Update Card Status",
          err.response.data.message
        );
        console.log(err.message);
      })
      .finally(() => {
        getDataCard(data.CardAccess.sid);
      });
  };

  const deleteCard = async (data) => {
    ApiClient()
      .del(`/cards/${data.cid}`)
      .then((res) => {
        openNotificationWithIcon("success", "Delete Card", res.message);

        // console.log(res.data.data);
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          "Delete Card",
          err.response.data.message
        );
        // console.log(err);
      })
      .finally(() => {
        getDataCard(data.CardAccess.sid);
      });
    // await axios
    //   .delete(`${config.BASEURL}/cards/${data.cid}`, config.HEADERS)
    //   .then((res) => {
    //     openNotificationWithIcon("success", "Delete Card", res.data.message);

    //     // console.log(res.data.data);
    //   })
    //   .catch((err) => {
    //     openNotificationWithIcon(
    //       "error",
    //       "Delete Card",
    //       err.response.data.message
    //     );
    //     // console.log(err);
    //   })
    //   .finally(() => {
    //     getDataCard(data.CardAccess.sid);
    //   });
  };
  const deleteAccount = async (data) => {
    ApiClient()
      .del(`/accounts/${data.aid}`)
      .then((res) => {
        openNotificationWithIcon("success", "Delete Account", res.message);
        deleteCard(data);

        // console.log(res.data.data);
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          "Delete Account",
          err.response.data.message
        );
        // console.log(err);
      });
    // await axios
    //   .delete(`${config.BASEURL}/accounts/${data.aid}`, config.HEADERS)
    //   .then((res) => {
    //     openNotificationWithIcon("success", "Delete Account", res.data.message);
    //     deleteCard(data);

    //     // console.log(res.data.data);
    //   })
    //   .catch((err) => {
    //     openNotificationWithIcon(
    //       "error",
    //       "Delete Account",
    //       err.response.data.message
    //     );
    //     // console.log(err);
    //   });
  };

  const handleExport = () => {
    const headings = [["CARD"]];
    const card = [
      {
        CARD: 11111111,
      },
      {
        CARD: 11111112,
      },
    ];
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings);
    utils.sheet_add_json(ws, card, { origin: "A2", skipHeader: true });
    utils.book_append_sheet(wb, ws, "Report");
    writeFile(wb, "card_template.xlsx");
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#DAFFFB" : "white",
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    // {
    //   title: "Timestamp",
    //   dataIndex: "timestamp",
    //   key: "timestamp",
    //   width: "20%",
    //   ...getColumnSearchProps("timestamp"),
    //   sorter: (a, b) => a.timestamp.length - b.timestamp.length,
    //   sortDirections: ["descend", "ascend"],
    // },
    {
      title: "Card ID",
      dataIndex: "username",
      key: "username",
      width: "150px",
      fixed: "left",
      ...getColumnSearchProps("username"),
      sorter: (a, b) => {
        return a.ID - b.ID;
      }, // (a, b) => a.card_uid.length - b.card_uid.length,
      defaultSortOrder: "ascend",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
      width: "200px",
      fixed: "left",
      ...getColumnSearchProps("phone"),
      // sorter: (a, b) => {
      //   return a - b;
      // }, // (a, b) => a.card_uid.length - b.card_uid.length,

      // sortDirections: ["descend", "ascend"],
    },
    {
      title: "Name",
      dataIndex: "full_name",
      key: "full_name",
      width: "200px",
      fixed: "left",
      ...getColumnSearchProps("full_name"),
    },
    // {
    //   title: "E-mail",
    //   dataIndex: "email",
    //   key: "email",
    //   width: "200px",
    //   ...getColumnSearchProps("email"),
    // },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: "200px",
      ...getColumnSearchProps("address"),
    },
    {
      title: "Block Name",
      dataIndex: "block_name",
      key: "block_name",
      width: "200px",
      align: "center",
      ...getColumnSearchProps("block_name"),
    },
    {
      title: "Block Number",
      dataIndex: "block_number",
      key: "block_number",
      width: "200px",
      align: "center",
      ...getColumnSearchProps("block_number"),
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      width: "150px",
      // fixed: "right",
      align: "center",
      ...getColumnSearchProps("balance"),
    },
    {
      title: "Expired Date",
      dataIndex: "expired_date",
      key: "expired_date",
      width: "150px",
      // fixed: "right",
      align: "center",
      ...getColumnSearchProps("expired_date"),
    },
    {
      title: "Master Card",
      key: "type",
      align: "center",
      width: "150px",
      fixed: "right",
      render: (_, record) => {
        // console.log(record);
        return (
          <Space size="middle">
            <Popconfirm
              title="Update card to Master Card"
              description="Are you sure to update this card status?"
              icon={<QuestionCircleOutlined style={{ color: "blue" }} />}
              onConfirm={() => {
                // updateAccountStatus(record);
                // deleteSite(record);
                updateMasterCard(record);
              }}
            >
              <Switch checked={record.CardAccess.type} />
            </Popconfirm>
          </Space>
        );
      },
    },
    {
      title: "Account Status",
      key: "status",
      align: "center",
      width: "150px",
      fixed: "right",
      render: (_, record) => {
        // console.log(record);
        return (
          <Space size="middle">
            <Popconfirm
              title="Update status account"
              description="Are you sure to update this account?"
              icon={<QuestionCircleOutlined style={{ color: "blue" }} />}
              onConfirm={() => {
                updateAccountStatus(record);
                // deleteSite(record);
              }}
            >
              <Switch checked={record.status} />
            </Popconfirm>
          </Space>
        );
      },
    },
    {
      title: "Card Status",
      key: "status",
      align: "center",
      width: "150px",
      fixed: "right",
      render: (_, record) => {
        // console.log(record);
        return (
          <Space size="middle">
            <Popconfirm
              title="Update status card"
              description="Are you sure to update this card?"
              icon={<QuestionCircleOutlined style={{ color: "blue" }} />}
              onConfirm={() => {
                updateCardStatus(record);
                // deleteSite(record);
              }}
            >
              <Switch
                defaultChecked={record.CardAccess.status}
                checked={record.CardAccess.status}
              />
            </Popconfirm>
          </Space>
        );
      },
    },
    // {
    //   title: "Edit",
    //   key: "edit",
    //   align: "center",
    //   width: "100px",
    //   fixed: "right",
    //   render: (_, record) => {
    //     // console.log(record);
    //     return (
    //       <Space size="middle">
    //         <Button
    //           onClick={() => {
    //             // openModal(record);
    //             // console.log(record);
    //           }}
    //         >
    //           Edit
    //         </Button>
    //       </Space>
    //     );
    //   },
    // },
    {
      title: "Delete",
      key: "delete",
      align: "center",
      width: "150px",
      fixed: "right",
      render: (_, record) => {
        // console.log(record);
        return (
          <Space size="middle">
            <Popconfirm
              title="Delete Account"
              description="Are you sure to delete this account?"
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              onConfirm={() => {
                deleteAccount(record);
              }}
            >
              <button className="delete-button">DELETE</button>
              {/* <Button danger>Delete</Button> */}
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    getSiteList();
    return () => {
      getSiteList();
    };
  }, []);
  return (
    <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
      {contextHolder}
      <Row align="middle" style={{ paddingBottom: "15px" }}>
        <Typography.Text
          style={{ paddingRight: "10px", fontWeight: "bold", fontSize: "16px" }}
        >
          SELECT SITE :
        </Typography.Text>

        <Select
          options={allSite}
          style={{ width: "20%" }}
          onSelect={(e) => {
            setButtonState(false);
            getDataCard(e.split("*")[1]);
            setSid(e.split("*")[1]);
          }}
        />
        <div style={{ paddingLeft: "25px" }}>
          <Space size="large">
            <button
              className="add-button"
              disabled={buttonState}
              onClick={() => {
                setIsModalCardRegistration(true);
              }}
            >
              ADD CARD
            </button>
            <button
              className="add-button"
              // disabled={buttonState}
              onClick={() => {
                handleExport();
                // setIsModalCardRegistration(true);
              }}
            >
              DOWNLOAD TEMPLATE
            </button>
          </Space>
        </div>
      </Row>

      <Table
        columns={columns}
        dataSource={dataCard}
        pagination={{
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} cards`,
          onChange: (e) => {
            // console.log(e);
          },
        }}
        rowKey={(record) => {
          return record.cid;
        }}
        scroll={{
          x: 1300,
        }}
        loading={isLoading}
      />
      {/* <Modal
        title="Insert Card"
        open={isModalCardRegistration}
        onOk={() => {
          console.log(newDataCard);
          setIsModalCardRegistration(false);
        }}
        onCancel={() => {
          setNewDataCard([]);
          setIsModalCardRegistration(false);
        }}
        width={900}
      > */}
      <FormCardRegistration
        getDataCard={getDataCard}
        openNotificationWithIcon={openNotificationWithIcon}
        data={sid}
        isModalCardRegistration={isModalCardRegistration}
        setIsModalCardRegistration={setIsModalCardRegistration}
      />
      {/* </Modal> */}
    </div>
  );
}
export default TableCardListAdmin;
