import React from "react";
import TableUser from "../../components/admin/TableUser";

function UserListPage() {
  return (
    // <BasePage>

    //   {/* <Sidebar title="USER LIST" path="/user-list"> */}
    //   {/* </Sidebar> */}
    // </BasePage>
    <div className="load-content">
      <TableUser />
    </div>
  );
}

export default UserListPage;
