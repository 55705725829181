import logo from "./logo.svg";
import "./App.css";

import IndexRoutes from "./routes/IndexRoutes";
import React from "react";
import { Provider as ProviderData } from "./context/DataContext";
import { ConfigProvider } from "antd";
function App() {
  return (
    <ProviderData>
      <ConfigProvider
        theme={{
          components: {
            Table: {
              headerBg: "#04364A",
              headerSortActiveBg: "#04364A",
              headerSortHoverBg: "#176B87",
              // footerBg: "#04364A",
              fixedHeaderSortActiveBg: "#176B87",
              // rowExpandedBg: "rgb(25, 25, 25)",
              headerColor: "rgb(255, 255, 255)",
              filterDropdownBg: "#DAFFFB",
              filterDropdownMenuBg: "#DAFFFB",
              expandIconBg: "#DAFFFB",
              headerBorderRadius: 12,
              // rowHoverBg: "#000000",
            },
            Menu: {
              itemColor: "rgb(255, 255, 255)",
              itemSelectedColor: "#000000",
              itemSelectedBg: "#64CCC5",

              itemHoverColor: "rgb(0, 0, 0,50)",
              itemHoverBg: "rgb(218, 255, 251)",
            },
            Modal: {
              // contentBg: "#64CCC5",
              headerBg: "#04364A",
              titleColor: "rgb(255,255,255)",
              titleLineHeight: 2,
            },
            Pagination: {
              itemSize: 32,
              // itemSizeSM: 50,
            },
          },
        }}
      >
        <IndexRoutes />
      </ConfigProvider>
    </ProviderData>
  );
}

export default App;
