import React, { useEffect, useState } from "react";
import { Form, Input, Modal, Typography, Space } from "antd";
import { ApiClient } from "../../api/ApiClient";

function FormUserEdit({
  data,
  isModalUserEdit,
  setIsModalUserEdit,
  openNotificationWithIcon,
  getUserList,
}) {
  const [popconfirm, setPopconfirm] = useState(false);

  const [form] = Form.useForm();

  const updateUser = async (data) => {
    const body = {
      address: data.address,
      email: data.email,
      full_name: data.full_name,
      username: data.username,
    };
    ApiClient()
      .patch(`/users/${data.uid}`, body)
      .then((res) => {
        openNotificationWithIcon("success", "Update User", res.message);
        getUserList();
        // console.log(res.data.data);
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          "Update User",
          err.response.data.message
        );
        // console.log(err);
      });
    // await axios
    //   .patch(`${config.BASEURL}/users/${data.uid}`, body, config.HEADERS)
    //   .then((res) => {
    //     openNotificationWithIcon("success", "Update User", res.data.message);
    //     getUserList();
    //     // console.log(res.data.data);
    //   })
    //   .catch((err) => {
    //     openNotificationWithIcon(
    //       "error",
    //       "Update User",
    //       err.response.data.message
    //     );
    //     // console.log(err);
    //   });
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data]);
  return (
    <Modal
      forceRender
      width="800px"
      // title="User Edit"
      title={
        <Typography.Title
          style={{
            color: "white",
            fontSize: 24,
            paddingLeft: 20,
          }}
        >
          User Edit
        </Typography.Title>
      }
      open={isModalUserEdit}
      // onOk={() => {
      //   // updateGate(form.getFieldsValue());
      //   // setIsModalUserEdit(false);
      //   updateUser(form.getFieldsValue());
      //   setIsModalUserEdit(false);
      //   // setPopconfirm(true);
      //   // updateUser(form.getFieldsValue());
      // }}
      onCancel={() => {
        setIsModalUserEdit(false);
      }}
      footer={[
        <Space key="spacebutton">
          <button
            key="printbutton"
            className="save-button"
            onClick={() => {
              updateUser(form.getFieldsValue());
              setIsModalUserEdit(false);
            }}
          >
            Update
          </button>
          <button
            key="closebutton"
            className="cancel-button"
            onClick={() => {
              setIsModalUserEdit(false);
            }}
          >
            CANCEL
          </button>
        </Space>,
        // <Button
        //   key="print"
        //   type="primary"
        //   onClick={() => {
        //     hanldePrint(record);
        //   }}
        // >
        //   Print
        // </Button>,
        // <Button
        //   key="close"
        //   onClick={() => {
        //     setIsModalOpen(false);
        //   }}
        // >
        //   Close
        // </Button>,
        // <Button key="link" href="https://google.com" type="primary">
        //   Search on Google
        // </Button>,
      ]}
    >
      <Form
        form={form}
        // disabled={!popconfirm}
        // name="site_edit"
        // onFinish={addSite}
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 15,
        }}
        layout="horizontal"
        autoComplete="off"
      >
        <Form.Item
          label="Site Name"
          name={["Site", "name"]}
          rules={[{ required: true }]}
        >
          <Input disabled={true} />
        </Form.Item>
        <Form.Item label="User ID" name="uid" rules={[{ required: true }]}>
          <Input disabled={true} />
        </Form.Item>
        <Form.Item
          label="Role"
          name={["Role", "name"]}
          rules={[{ required: true }]}
        >
          <Input disabled={true} />
        </Form.Item>
        <Form.Item label="Full Name" name="full_name">
          <Input />
        </Form.Item>

        <Form.Item label="Address" name="address">
          <Input />
        </Form.Item>
        <Form.Item label="E-Mail" name="email" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Username" name="username">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default FormUserEdit;
