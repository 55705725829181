import React from "react";
import FormUserInfo from "../../components/user/FormUserInfo";

function InfoUser() {
  return (
    <div className="load-content">
      <FormUserInfo />
    </div>
  );
}

export default InfoUser;
