import { Form, Input, Popconfirm, Row, Checkbox, notification } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { ApiClient } from "../../api/ApiClient";

function FormUserInfo() {
  const [isModalResetPassword, setIsModalResetPassword] = useState(false);
  const [checkedChangePassword, setCheckedChangePassword] = useState(false);
  const [form] = Form.useForm();
  const [formForgotPassword] = Form.useForm();
  const uid = localStorage.getItem("uid");

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
    });
  };

  const getUserInfo = async () => {
    ApiClient()
      .get(`/users/search?field=uid&data=${uid}&detail=0`)
      .then((res) => {
        form.setFieldsValue(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // await axios
    //   .get(
    //     `${config.BASEURL}/users/search?field=uid&data=${uid}&detail=0`,
    //     config.HEADERS
    //   )
    //   .then((res) => {
    //     form.setFieldsValue(res.data.data);

    //     // console.log(res.data.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  const updatePassword = async (password) => {
    const body = {
      new_credential: password.new_password,
      old_credential: password.old_password,
    };

    ApiClient()
      .patch(`/users/${localStorage.getItem("uid")}/credential`, body)
      .then((res) => {
        openNotificationWithIcon("success", "Update Password", res.message);

        console.log(res.data);
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          "Update Password",
          err.response.data.message
        );
      });

    // await axios
    //   .patch(
    //     `${config.BASEURL}/users/${localStorage.getItem("uid")}/credential`,
    //     body,
    //     config.HEADERS
    //   )
    //   .then((res) => {
    //     openNotificationWithIcon("success", "Update Password", res.message);
    //     // console.log(res.data);
    //   })
    //   .catch((err) => {
    //     openNotificationWithIcon(
    //       "error",
    //       "Update Password",
    //       err.response.data.message
    //     );
    //   });
  };

  const updateUser = async (data) => {
    const body = {
      address: data.address,
      phone: data.phone,
      email: data.email,
      full_name: data.full_name,
      // username: data.username,
    };

    ApiClient()
      .patch(`/users/${uid}`, body)
      .then((res) => {
        openNotificationWithIcon("success", "Update Data", res.message);
        if (checkedChangePassword) {
          updatePassword(data);
        } else {
          getUserInfo();
        }
        // console.log(res.data.data);
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          "Update Data",
          err.response.data.message
        );
        // console.log(err);
      });
    // await axios
    //   .patch(`${config.BASEURL}/users/${uid}`, body, config.HEADERS)
    //   .then((res) => {
    //     openNotificationWithIcon("success", "Update Data", res.message);
    //     if (checkedChangePassword) {
    //       updatePassword(data);
    //     } else {
    //       getUserInfo();
    //     }
    //     // console.log(res.data.data);
    //   })
    //   .catch((err) => {
    //     openNotificationWithIcon(
    //       "error",
    //       "Update Data",
    //       err.response.data.message
    //     );
    //     // console.log(err);
    //   });
  };
  useEffect(() => {
    getUserInfo();
    return () => getUserInfo();
  }, []);
  return (
    <>
      {contextHolder}
      <Form
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 8,
        }}
        form={form}
        // onFinish={updateUser}
        layout="horizontal"
      >
        <Form.Item label="Full Name" name="full_name">
          <Input tyle={{ width: 200 }} />
        </Form.Item>
        <Form.Item label="Phone" name="phone">
          <Input tyle={{ width: 200 }} />
        </Form.Item>
        <Form.Item label="Address" name="address">
          <Input />
        </Form.Item>
        <Form.Item
          label="E-mail"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your E-Mail!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        {/* <Form.Item
          label="Username"
          name="username"
          rules={[
            {
              required: true,
              message: "Please input your Username!",
            },
          ]}
        >
          <Input />
        </Form.Item> */}
        <Form.Item
          label="Change Password"
          name="change_password"
          initialValue={checkedChangePassword}
          // rules={[
          //   {
          //     required: true,
          //     message: "Please input your Username!",
          //   },
          // ]}
        >
          <Checkbox
            checked={checkedChangePassword}
            // defaultCheck/ed
            onChange={() => {
              setCheckedChangePassword(!checkedChangePassword);
            }}
          />
        </Form.Item>
        <Form.Item
          label="Old Password"
          name="old_password"
          rules={[
            {
              required: checkedChangePassword,
              message: "Please input your Old Password!",
            },
          ]}
        >
          <Input.Password
            disabled={!checkedChangePassword}
            style={{ width: 200 }}
          />
        </Form.Item>
        <Form.Item
          label="New Password"
          name="new_password"
          rules={[
            {
              required: checkedChangePassword,
              message: "Please input your New Password!",
            },
          ]}
        >
          <Input.Password
            disabled={!checkedChangePassword}
            style={{ width: 200 }}
          />
        </Form.Item>
        <Form.Item
          label="Confirm New Password"
          name="confirm_new_password"
          rules={[
            {
              required: checkedChangePassword,
              message: "Please input your New Password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("new_password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The new password that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password
            disabled={!checkedChangePassword}
            style={{ width: 200 }}
          />
        </Form.Item>
        <Row align="center">
          <Popconfirm
            title="Update User"
            description="Are you sure to update this user info?"
            icon={<QuestionCircleOutlined style={{ color: "blue" }} />}
            onConfirm={() => {
              updateUser(form.getFieldsValue());
            }}
          >
            <button className="add-button">SAVE</button>
            {/* <Button type="primary">SAVE</Button> */}
          </Popconfirm>
        </Row>
      </Form>
    </>
  );
}

export default FormUserInfo;
