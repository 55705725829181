import { Row } from "antd";
import React from "react";

function BasePage({ children }) {
  return (
    <Row style={{ height: "100vh", overflow: "auto", display: "flex" }}>
      {children}
    </Row>
  );
}

export default BasePage;
