import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Row,
  Table,
  Space,
  Popconfirm,
  Modal,
  Switch,
  Typography,
} from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { ApiClient } from "../../api/ApiClient";
import { read, utils } from "xlsx";

let id = 1;

function FormCardRegistration({
  data,
  isModalCardRegistration,
  setIsModalCardRegistration,
  openNotificationWithIcon,
  getDataCard,
}) {
  const [form] = Form.useForm();
  const [cardData, setCardData] = useState([]);

  const postCard = async () => {
    for (var i = 0; i < cardData.length; i++) {
      await ApiClient()
        .post(`/cards/create`, {
          card_uid: cardData[i].card_id,
          sid: data,
          status: cardData[i].status,
        })
        .then((res) => {
          openNotificationWithIcon("success", "Create Card", res.message);
        })
        .catch((err) => {
          // console.log(err);
          openNotificationWithIcon(
            "error",
            "Create Card",
            //
            err.response.data ? err.response.data.message : "Failed Add Card"
          );
        });
    }
    // cardData.forEach(async (datas) => {

    getDataCard(data);

    // });
    // await axios
    //   .post(
    //     `${config.BASEURL}/cards/create`,
    //     {
    //       card_uid: datas.card_id,
    //       sid: data,
    //       status: datas.status,
    //     },
    //     config.HEADERS
    //   )
    //   .then((res) => {
    //     openNotificationWithIcon("success", "Create Card", res.data.message);
    //     getDataCard(data);
    //   })
    //   .catch((err) => {
    //     openNotificationWithIcon(
    //       "error",
    //       "Create Card",
    //       err.response.data.message
    //     );
    //   });
  };

  const columns = [
    { title: "Card ID", dataIndex: "card_id", key: "card_id", width: "200px" },
    // { title: "Name", dataIndex: "name", key: "name" },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: "200px",
      render: (_, record) => {
        return (
          <Space size="middle">
            <Switch
              disabled={true}
              defaultChecked={record.status}
              checked={record.status}
            />
          </Space>
        );
      },
    },
    {
      title: "Delete",
      key: "delete",
      align: "center",
      width: "200px",
      render: (_, record) => {
        // console.log(record);
        return (
          <Space size="middle">
            <Popconfirm
              title="Delete the card"
              description="Are you sure to delete this card?"
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              onConfirm={() => {
                var array = [...cardData]; // make a separate copy of the array
                var index = array.map((v) => v.card_id).indexOf(record.card_id);

                if (index !== -1) {
                  array.splice(index, 1);
                  setCardData(array);
                }
              }}
            >
              <button className="delete-button">DELETE</button>
              {/* <Button
                onClick={() => {
                  // console.log(record);
                }}
                danger
              >
                Delete
              </Button> */}
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const addCardList = (value) => {
    // id++;
    // console.log(cardList.length);
    if (cardData.length != 0) {
      setCardData((arr) => [
        ...arr,
        {
          no: id++,
          key: id,
          status: 1,
          card_id: value.id,
        },
      ]);
    } else {
      // console.log("first");
      setCardData([
        {
          no: id++,
          key: id,
          status: 1,
          card_id: value.id,
        },
      ]);

      // setNewData
    }
    // console.log(cardList);
    // setNewData(cardList);
  };
  const handleImport = ($event) => {
    const files = $event.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;

        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          var tmp = [];
          rows.map((data, idx) => {
            tmp.push({
              no: idx,
              key: idx,
              status: 1,
              card_id: data.CARD.toString(),
            });
          });
          setCardData(tmp);
          // console.log(rows);
          // setMovies(rows);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };
  useEffect(() => {
    setCardData([]);

    // console.log("USE", cardList);
  }, [data]);

  return (
    <Modal
      forceRender
      title={
        <Typography.Title
          style={{
            color: "white",
            fontSize: 24,
            paddingLeft: 20,
          }}
        >
          Insert Card
        </Typography.Title>
      }
      // title="Insert Card"
      open={isModalCardRegistration}
      onOk={() => {
        postCard();

        setIsModalCardRegistration(false);
      }}
      onCancel={() => {
        setCardData([]);

        setIsModalCardRegistration(false);
      }}
      width={900}
      footer={[
        <Space key="spacebutton">
          <button
            key="printbutton"
            className="save-button"
            onClick={() => {
              // updateSite(form.getFieldValue());
              postCard();

              setIsModalCardRegistration(false);
              // hanldePrint(record);
            }}
          >
            SAVE
          </button>
          <button
            key="closebutton"
            className="cancel-button"
            onClick={() => {
              setCardData([]);

              setIsModalCardRegistration(false);
            }}
          >
            CANCEL
          </button>
        </Space>,
        // <Button
        //   key="print"
        //   type="primary"
        //   onClick={() => {
        //     hanldePrint(record);
        //   }}
        // >
        //   Print
        // </Button>,
        // <Button
        //   key="close"
        //   onClick={() => {
        //     setIsModalOpen(false);
        //   }}
        // >
        //   Close
        // </Button>,
        // <Button key="link" href="https://google.com" type="primary">
        //   Search on Google
        // </Button>,
      ]}
    >
      <Form
        labelCol={{
          span: 2,
        }}
        // wrapperCol={{
        //   span: 30,
        // }}
        form={form}
        // onFinish={addCardList}
        layout="horizontal"
        // disabled={componentDisabled}
        style={{
          // alignSelf: "center",

          // maxWidth: 600,
          paddingRight: "15px",
          paddingLeft: "15px",
        }}
      >
        <Form.Item rules={[{ required: true }]} label="Input ID" name="id">
          <Input></Input>
        </Form.Item>

        <Row justify={"end"}>
          <Form.Item>
            <Space>
              <button
                className="add-button"
                onClick={() => {
                  addCardList(form.getFieldsValue());
                }}
              >
                ADD
              </button>
              <input
                type="file"
                className="add-button"
                accept=".xlsx, .xls, .csv"
                required
                onChange={(e) => {
                  handleImport(e);
                  // console.log(e.target);
                }}
              />
            </Space>
            {/* <button
              className="add-button"
              onClick={() => {
                addCardList(form.getFieldsValue());
              }}
            >
              ADD
            </button> */}
            {/* <Button
              className="submit=btn"
              htmlType="submit"
              style={{
                display: "flex",

                justifyContent: "end",
              }}
            >
              Add
            </Button> */}
          </Form.Item>
        </Row>

        <Form.Item>
          <Table
            pagination={{ pageSize: 5 }}
            key="table"
            columns={columns}
            dataSource={cardData}
          />
        </Form.Item>

        <Row justify={"end"}>
          <Form.Item>
            <Popconfirm
              title="Delete the card"
              description="Are you sure to delete this card?"
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              onConfirm={() => {}}
            ></Popconfirm>
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  );
}

export default FormCardRegistration;
