import { BulbFilled, HeartFilled } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Card, Col, Row, Table, Typography } from "antd";
import TableCardListUser from "./TableCardListUser";
import "../../App.css";
import { ApiClient } from "../../api/ApiClient";

function UserHome({ socketEvent }) {
  const [statusSite, setStatusSite] = useState({});
  const [extrasData, setExtrasData] = useState([]);

  const getSiteStatus = () => {
    ApiClient()
      .get(
        `/report/health?filter=${localStorage.getItem("sid")}&limit=1
`
      )
      .then((res) => {
        // console.log(res.data);
        if (res.data) {
          var extras = res.data[0].extras;
          var key = Object.keys(extras);
          var value = Object.values(extras);
          var tmpItems = [];
          setStatusSite(res.data[0]);
          key.map((data, idx) => {
            tmpItems.push({
              id: idx,
              items: data,
              conditions: value[idx],
            });
          });
          setExtrasData(tmpItems);
          // console.log(tmpItems);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    //  axios
    //   .get(
    //     `${config.BASEURL}/report/health?filter=${localStorage.getItem(
    //       "sid"
    //     )}&limit=1`,
    //     config.HEADERS
    //   )
    //   .then((res) => {
    //     setStatusSite(res.data.data[0]);
    //     // console.log(res.data.data[0]);
    //   })
  };

  const columns = [
    {
      title: "Items",
      dataIndex: "items",
      key: "items",
      width: "50%",
      fixed: "left",
      align: "center",
    },
    {
      title: "Conditions",
      dataIndex: "conditions",
      key: "conditions",
      width: "50%",
      fixed: "left",
      align: "center",
    },
  ];

  const handleSocket = (data) => {
    try {
      const newData = JSON.parse(data);
      if (newData.msg_type === "health") {
        getSiteStatus();
      }
    } catch (err) {
      // throw err
      console.log(err);
    }
  };
  useEffect(() => {
    getSiteStatus();
    return () => getSiteStatus();
  }, []);

  useEffect(() => {
    if (socketEvent !== "") {
      handleSocket(socketEvent);
    }
  }, [socketEvent]);
  // console.log(statusSite);
  return (
    <div style={{ padding: "15px" }}>
      <Row justify="center">
        <Card
          key={statusSite.site_name}
          title={
            <Row gutter={30}>
              <Col span={20}>
                <Typography.Text
                  style={{
                    color: "white",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  {statusSite.site_name ? statusSite.site_name : "No Data"}
                </Typography.Text>
              </Col>
              <Col span={2}>
                <HeartFilled
                  className={statusSite.status === 1 ? "" : "blink"}
                  style={{
                    paddingTop: "5px",
                    fontSize: "20px",
                    color:
                      statusSite.status === 1
                        ? "green"
                        : statusSite.status === 2
                        ? "yellow"
                        : "red",
                  }}
                />
              </Col>
            </Row>
          }
          headStyle={{
            backgroundColor: "#04364A",
            color: "white",
          }}
          // hoverable={false}
          bordered={false}
          style={{
            width: "75%",
            backgroundColor: "#DAFFFB",
            // minWidth: "250px",

            // color: "green",
            // flexDirection: "row",
          }}
        >
          <Row
            align="stretch"
            style={{
              paddingBottom: "10px",
              alignContent: "center",

              justifyContent: "center",
            }}
            gutter={24}
          >
            <Col span={12}>
              <Row
                align="stretch"
                style={{
                  paddingBottom: "10px",
                  alignContent: "center",

                  justifyContent: "center",
                }}
              >
                <Typography.Text
                  style={{ fontSize: "14px", fontWeight: "bold" }}
                >
                  SERVER STATUS
                </Typography.Text>
                <BulbFilled
                  style={{
                    paddingLeft: "15px",
                    color: statusSite.status === 1 ? "green" : "red",
                  }}
                />
              </Row>
              <Row
                align="stretch"
                gutter={30}
                style={{ paddingBottom: "10px" }}
              >
                {statusSite.gates === undefined ? (
                  <></>
                ) : (
                  statusSite.gates.map((data, idx) => {
                    return (
                      // <Card.Grid
                      //   key={idx}
                      //   style={{
                      //     width: "50%",
                      //     padding: "0px",
                      //     backgroundColor: "#DAFFFB",
                      //   }}
                      //   hoverable={false}
                      // >
                      <Col key={idx} span={12}>
                        <Typography.Text
                          style={{
                            paddingLeft: "5px",
                            fontSize: "14px",
                            fontWeight: "bold",
                          }}
                        >
                          {data.gate_name}
                        </Typography.Text>

                        <Row
                          style={{
                            // display: "flex",
                            paddingTop: "15px",
                            paddingLeft: "5px",
                          }}
                          gutter={30}
                          key={idx}
                          // align="middle"
                        >
                          <Col span={12}>
                            <Typography.Text className="card-text-body">
                              Controller
                            </Typography.Text>
                          </Col>
                          <Col
                            span={12}
                            style={{
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <BulbFilled
                              style={{
                                // paddingLeft: "14px",
                                // alignSelf: "center",
                                color: data.status === 1 ? "green" : "red",
                              }}
                              //
                            />
                          </Col>
                        </Row>

                        {/* <Row
                      style={{
                        // display: "flex",
                        // paddingTop: "15px",
                        paddingLeft: "5px",
                      }}
                      gutter={30}
                    >
                      <Col span={12}>
                        <Typography.Text className="card-text-body">
                          Camera
                        </Typography.Text>
                      </Col>
                      <Col
                        span={12}
                        style={{
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <BulbFilled
                          style={{
                            // paddingLeft: "30px",
                            // alignSelf: "flex-end",
                            color: data.status === 1 ? "green" : "red",
                          }}
                        />
                      </Col>
                    </Row> */}

                        <Row
                          style={{
                            // display: "flex",
                            // paddingTop: "15px",
                            paddingLeft: "5px",
                          }}
                          // gutter={24}
                        >
                          <Col span={12}>
                            <Typography.Text className="card-text-body">
                              Last Transaction
                            </Typography.Text>
                          </Col>
                          <Col span={12}>
                            <Typography.Text className="card-text-body">
                              {data.last_trans}
                            </Typography.Text>
                          </Col>
                        </Row>
                        <Row
                          style={{
                            // display: "flex",
                            // paddingTop: "15px",
                            paddingLeft: "5px",
                          }}
                        >
                          <Col span={12}>
                            <Typography.Text className="card-text-body">
                              Last Report
                            </Typography.Text>
                          </Col>

                          <Col span={12}>
                            <Typography.Text className="card-text-body">
                              {data.last_report}
                            </Typography.Text>
                          </Col>
                        </Row>
                      </Col>
                      // </Card.Grid>
                    );
                  })
                )}
              </Row>
            </Col>
            <Col span={12}>
              <Row align="stretch" gutter={24}>
                <Table
                  columns={columns}
                  dataSource={extrasData}
                  style={{
                    width: "100%",
                  }}
                  rowKey={(record) => {
                    return record.id;
                  }}
                  pagination={{ pageSize: 3 }}
                />
              </Row>
            </Col>
          </Row>
        </Card>
      </Row>

      <br />
      {/* <Table columns={columns} dataSource={data} /> */}
      <Row justify="center" style={{ display: "flow-root" }}>
        <TableCardListUser />
      </Row>
    </div>
  );
}
export default UserHome;
