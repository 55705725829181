import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Popconfirm,
  InputNumber,
  Space,
  Typography,
  DatePicker,
  Button,
} from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { ApiClient } from "../../api/ApiClient";
import dayjs from "dayjs";

function FormUserEdit({
  data,
  isModalOpen,
  setIsModalOpen,
  notification,
  getDataCard,
}) {
  const [form] = Form.useForm();
  const [popconfirm, setPopconfirm] = useState(false);
  const [disabledModalButton, setDisabledModalButton] = useState(false);

  useEffect(() => {
    form.setFieldsValue(data);
    // return () => {
    //   form.setFieldsValue(data);
    // };
  }, [data]);

  const updateAccount = async (account) => {
    // console.log(account);
    // console.log(data);
    setPopconfirm(false);
    // var f = dayjs(date).unix();
    // console.log(account.expired_dates);
    const body = {
      full_name: account.full_name,
      address: account.address,
      block_name: account.block_name,
      block_number: account.block_number,
      balance: parseInt(account.balance),
      status: account.CardAccess.status,
      phone: account.phone,
      license: account.license,
      expired_date: dayjs(
        dayjs(account.expired_dates).format("YYYY-MM-DD")
      ).unix(),
    };

    ApiClient()
      .patch(`/accounts/${account.aid}`, body)
      .then((res) => {
        // console.log(res.data.data);
        notification("success", "Update Account", res.message);
        setIsModalOpen(false);
        setDisabledModalButton(false);
        getDataCard();
      })
      .catch((err) => {
        notification("error", "Update Account", err.response.data.message);
        // console.log(err);
      });

    // await axios
    //   .patch(`${config.BASEURL}/accounts/${account.aid}`, body, config.HEADERS)
    //   .then((res) => {
    //     // console.log(res.data.data);
    //     if (res.status == 200) {
    //       notification("success", "Update Account", res.data.message);
    //       setIsModalOpen(false);
    //       setDisabledModalButton(false);
    //       getDataCard();
    //     }
    //   })
    //   .catch((err) => {
    //     notification("error", "Update Account", err.response.data.message);
    //     console.log(err);
    //   });
  };

  const forceResetPassword = async () => {
    const body = {
      new_credential: data.username.substr(data.username.length - 6),
    };

    await ApiClient()
      .patch(`/accounts/${data.aid}/force?channel=pin`, body)
      .then((res) => {
        notification("success", "Reset Password Account", res.message);
      })
      .catch((err) => {
        notification(
          "error",
          "Reset Password Account",
          err.response.data.error
        );
      });
  };

  const forceResetCardDevice = async () => {
    await ApiClient()
      .patch(`/accounts/${data.aid}/reset`)
      .then((res) => {
        notification("success", "Reset Device Account", res.message);
      })
      .catch((err) => {
        notification("error", "Reset Device Account", err.response.data.error);
      });
  };

  return (
    <Modal
      forceRender
      title={
        <Typography.Title
          style={{
            color: "white",
            fontSize: 24,
            paddingLeft: 20,
          }}
        >
          User Edit
        </Typography.Title>
      }
      open={isModalOpen}
      okButtonProps={{ disabled: disabledModalButton }}
      cancelButtonProps={{ disabled: disabledModalButton }}
      onOk={() => {
        setPopconfirm(true);
        setDisabledModalButton(true);
        // updateAccount(form.getFieldValue());
        // setIsModalOpen(false);
      }}
      onCancel={() => {
        // form.resetFields();
        setPopconfirm(false);
        setIsModalOpen(false);
      }}
      footer={
        [
          // <Space size="middle" key="space-button">
          //   <button
          //     key="cancelbutton"
          //     className="cancel-button"
          //     onClick={() => {
          //       setIsModalOpen(false);
          //     }}
          //   >
          //     CANCEL
          //   </button>
          //   <button
          //     key="savebutton"
          //     className="save-button"
          //     htmlType="submit"
          //     onClick={() => {
          //       // setPopconfirm(true);
          //       setDisabledModalButton(true);
          //     }}
          //   >
          //     SAVE
          //   </button>
          // </Space>,
        ]
      }
      mask={false}
      maskClosable={false}
    >
      <Form
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 17,
        }}
        form={form}
        onFinish={() => {
          var errCount = 0;
          // const tmp = form.getFieldsError();
          // tmp.map((o) => {
          //   if (o.errors.length > 0) {
          //     errCount += 1;
          //   }
          // });

          // if (errCount == 0) {
          setPopconfirm(true);
          setDisabledModalButton(true);
          // }
        }}
        // initialValues={data}

        layout="horizontal"
        style={{ paddingRight: "15px", paddingLeft: "15px" }}
      >
        <Form.Item
          label="Card ID"
          name="username"
          // initialValue={data.username}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input disabled={true}></Input>
        </Form.Item>

        <Form.Item
          label="Full Name"
          name="full_name"
          rules={[
            {
              required: true,
            },
          ]}
          // initialValue={data.full_name}
        >
          <Input></Input>
        </Form.Item>
        <Form.Item
          label="Phone Number"
          name="phone"
          // initialValue={data.username}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="License"
          name="license"
          // initialValue={data.username}
          // rules={[
          //   {
          //     required: true,
          //   },
          // ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Address" name="address" initialValue={data.address}>
          <Input></Input>
        </Form.Item>
        <Form.Item
          label="Block Name"
          name="block_name"
          // initialValue={data.block_name}
        >
          <Input></Input>
        </Form.Item>
        <Form.Item
          label="Block Number"
          name="block_number"
          // initialValue={data.block_number}
        >
          <Input></Input>
        </Form.Item>
        <Form.Item label="Balance" name="balance">
          <InputNumber></InputNumber>
        </Form.Item>
        <Form.Item label="Expired Date" name="expired_dates">
          <DatePicker />
          {/* <Input /> */}
        </Form.Item>

        <Form.Item label="Reset Password">
          {/* <div style={{ display: "flex", justifyContent: "center" }}> */}

          <Popconfirm
            title="Reset Password"
            description={`Reset this user ${data.full_name} password ?`}
            placement="top"
            okText="Yes"
            cancelText="No"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            onConfirm={forceResetPassword}
          >
            <Button type="primary" danger>
              Reset
            </Button>
          </Popconfirm>
          {/* </div> */}
        </Form.Item>

        <Form.Item label="Reset Device">
          {/* <div style={{ display: "flex", justifyContent: "center" }}> */}

          <Popconfirm
            title="Reset Device"
            description={`Reset this user ${data.full_name} device ?`}
            placement="top"
            okText="Yes"
            cancelText="No"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            onConfirm={forceResetCardDevice}
          >
            <Button type="primary" danger>
              Reset Device
            </Button>
          </Popconfirm>
          {/* </div> */}
        </Form.Item>
        <Form.Item>
          <div
            style={{
              display: "flex",
              // paddingLeft: 150,
              justifyContent: "right",
              justifyItems: "right",
              justifySelf: "right",
              // alignItems: "flex-end",
              // alignContent: "flex-end",
            }}
          >
            <Space size="large" key="space-button">
              <button
                key="cancelbutton"
                className="cancel-button"
                onClick={() => {
                  setIsModalOpen(false);
                }}
              >
                CANCEL
              </button>
              <Popconfirm
                title="Confirm User Update"
                description="Are you sure to update this user info?"
                placement="top"
                onConfirm={() => {
                  updateAccount(form.getFieldValue());
                }}
                onCancel={() => setPopconfirm(false)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  key="savebutton"
                  className="save-button"
                  htmlType="submit"
                >
                  SAVE
                </Button>
              </Popconfirm>
            </Space>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default FormUserEdit;
