import React from "react";
import Cards from "../components/admin/Cards";

import UserHome from "../components/user/UserHome";
import "../App.css";

function HomePage({ socketEvent }) {
  const role = localStorage.getItem("credent");

  return (
    <div className="load-content">
      {role !== "1" ? (
        <Cards socketEvent={socketEvent} />
      ) : (
        <UserHome socketEvent={socketEvent} />
      )}
    </div>
  );
}

export default HomePage;
