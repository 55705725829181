import { SearchOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import React, { useRef, useState, useEffect } from "react";
import Highlighter from "react-highlight-words";
import {
  Button,
  Input,
  Space,
  Table,
  Switch,
  Row,
  Modal,
  Popconfirm,
  notification,
} from "antd";
import axios from "axios";
import config from "../../config/global";
import FormUserRegistration from "./FormUserRegistration";
import FormUserEdit from "./FormUserEdit";
import { ApiClient } from "../../api/ApiClient";
import FormResetPassword from "./FormResetPassword";

function TableTicket() {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const [listTicket, setListTicket] = useState([]);

  const searchInput = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [isModalResetPassword, setIsModalResetPassword] = useState(false);

  const uid = localStorage.getItem("uid");
  const rv = localStorage.getItem("credent");

  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type, message, description) => {
    // console.log(type, message, description);
    api[type]({
      message: message,
      description: description,
    });
  };

  const getTicket = () => {
    var problem = "";
    if (rv === "3" || rv === "4") {
      problem = "Reset Password User";
    } else {
      problem = "Reset Password Account";
    }
    ApiClient()
      .get("/support")
      .then((res) => {
        setListTicket(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUserInfo = (user) => {
    ApiClient()
      .get(`/users/search?field=email&data=${user.reported_by}&detail=1`)
      .then((res) => {
        openNotificationWithIcon("info", "Get User Data", res.message);
        res.data.ticket = user;

        setUserInfo(res.data);
        setIsModalResetPassword(true);
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          "Get User Data",
          err.response.data.message
        );

        openNotificationWithIcon(
          "error",
          "Notes",
          "Please Decline this Ticket"
        );

        console.log(err);
      });
  };

  const postSolveTicket = async (data) => {
    const ticket = {
      condition: data.condition,
      ticket: data.ticket,
      updated_by: uid,
    };
    ApiClient()
      .post("/support/solve", ticket)
      .then((res) => {
        openNotificationWithIcon("info", "Succes Update Ticket", res.message);
        getTicket();
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#DAFFFB" : "white",
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    // {
    //   title: "Timestamp",
    //   dataIndex: "timestamp",
    //   key: "timestamp",
    //   width: "20%",
    //   ...getColumnSearchProps("timestamp"),
    //   sorter: (a, b) => a.timestamp.length - b.timestamp.length,
    //   sortDirections: ["descend", "ascend"],
    // },
    {
      title: "Reported Date",
      dataIndex: "reported_date",
      key: "reported_date",
      width: "150px",
      ...getColumnSearchProps("reported_date"),
      sorter: (a, b) => {
        return a.ID - b.ID;
      },
      fixed: "left",
      defaultSortOrder: "ascend",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Reported User",
      dataIndex: "reported_by",
      key: "reported_by",
      width: "150px",
      ...getColumnSearchProps("reported_by"),
    },
    {
      title: "Problem",
      dataIndex: "problem",
      key: "problem",
      width: "150px",
      ...getColumnSearchProps("problem"),
    },
    {
      title: "Status",
      dataIndex: "solve_condition",
      key: "solve_condition",
      width: "150px",
      ...getColumnSearchProps("solve_condition"),
    },

    {
      title: "Action",
      key: "edit",
      align: "center",
      width: "150px",
      render: (_, record) => {
        // console.log(record);
        return (
          <>
            {record.progress === 0 ? (
              <Space size="small">
                <button
                  className="save-button"
                  onClick={() => {
                    record.condition = "Accept";
                    // postApproveTicket(record);
                    getUserInfo(record);

                    // setDataUser(record);
                    // setIsModalUserEdit(true);
                  }}
                >
                  Accept
                </button>
                {/* <Button
                  type="primary"
                  onClick={() => {
                    record.condition = "Accept";
                    postApproveTicket(record);
                    getUserInfo(record);

                    // setDataUser(record);
                    // setIsModalUserEdit(true);
                  }}
                >
                  Accept
                </Button> */}
                <button
                  className="cancel-button"
                  onClick={() => {
                    record.condition = "Decline";
                    postSolveTicket(record);
                    // setDataUser(record);
                    // setIsModalUserEdit(true);
                  }}
                >
                  Decline
                </button>
                {/* <Button

                  //   type="primary"
                  onClick={() => {
                    record.condition = "Decline";
                    postSolveTicket(record);
                    // setDataUser(record);
                    // setIsModalUserEdit(true);
                  }}
                  style={{
                    backgroundColor: "red",
                    color: "white",
                  }}
                >
                  Decline
                </Button> */}
              </Space>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    getTicket();
    return () => getTicket();
  }, []);

  //   useEffect(() => {}, [recordData]);
  return (
    <>
      {contextHolder}
      <Row
        style={{
          display: "flow-root",
          paddingLeft: "15px",
          paddingRight: "15px",
        }}
      >
        <Table
          columns={columns}
          dataSource={listTicket}
          rowKey={(record) => {
            return record.reported_date;
          }}
          scroll={{
            x: 1300,
          }}
          pagination={{
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} ticket`,
            onChange: (e) => {
              // console.log(e);
            },
          }}
          loading={isLoading}
        />
      </Row>
      <FormResetPassword
        data={userInfo}
        isModalResetPassword={isModalResetPassword}
        setIsModalResetPassword={setIsModalResetPassword}
        openNotificationWithIcon={openNotificationWithIcon}
        postSolveTicket={postSolveTicket}
      />
    </>
  );
}

export default TableTicket;
