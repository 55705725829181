import axios from "axios";
import createDataContext from "./BaseContext";
import config from "../config/global";

// const url = 'http://192.168.88.175:5052/api/v1';
const url = config.BASEURL;
let dataUser = [];
const dataReducer = (state, action) => {
  switch (action.type) {
    case "getAllSite":
      //   console.log(action);
      //   console.log(getDataAkurasi(action.payload.interval, action.payload.ruas));
      // console.log(action.payload);
      return { DataAllSite: action.payload };

    case "getAllLog":
      // console.log(action.payload);
      return { DataAllLog: action.payload };

    case "getDataUser":
      dataUser = action.payload;
      return { DataUser: action.payload };

    case "returnDataUser":
      // console.log(dataUser);
      return { DataUser: dataUser };

    case "clear": {
      return { DataAkurasi: [], DataSensor: [] };
    }
    default:
      return;
  }
};

const getAllSite =
  (dispath) =>
  ({ onComplete, onError, onProcess }) => {
    if (onProcess) onProcess();
    axios
      .get(`${url}/sites`)
      .then((res) => {
        // console.log(res.data);
        dispath({ type: "getAllSite", payload: res.data.data });
        if (onComplete) onComplete();
      })
      .catch((err) => {
        if (onError) onError();
      });
  };

const getAllLog =
  (dispath) =>
  ({ onComplete, onError, onProcess }) => {
    if (onProcess) onProcess();
    axios
      .get(`${url}/status/`)
      .then((res) => {
        dispath({ type: "getAllLog", payload: res.data });
        if (onComplete) onComplete();
      })
      .catch((err) => {
        if (onError) onError();
        console.log(err);
      });
  };

const getDataUser =
  (dispath) =>
  ({ user, onComplete, onError, onProcess }) => {
    if (onProcess) onProcess();
    // console.log(user);
    axios
      .post(`${url}/users/login`, {
        data: { idUser: user },
      })
      .then((res) => {
        // console.log('res.data', res.data);
        dispath({ type: "getDataUser", payload: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

const returnDataUser = (dispath) => () => {
  dispath({ type: "returnDataUser" });
};

const clear = (dispath) => () => {
  dispath({ type: "clear" });
};
export const { Context, Provider } = createDataContext(
  dataReducer,
  {
    getAllSite,
    getAllLog,
    getDataUser,
    returnDataUser,
    clear,
  },
  {
    DataAkurasi: [],
    DataSensor: [],
    DataUser: [],
  }
);
