import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Select,
  Space,
  Table,
  Switch,
  Popconfirm,
  Form,
  Modal,
  InputNumber,
  Typography,
} from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { ApiClient } from "../../api/ApiClient";

function FormSiteEdit({
  record,
  getSiteList,
  isModalSiteOpen,
  setIsModalSiteOpen,
  openNotificationWithIcon,
}) {
  const [form] = Form.useForm();
  const [gateList, setGateList] = useState([]);

  const addSite = (val) => {
    const gate = {
      id: Math.floor(Math.random() * 1000) + 1,
      gate_name: val.gate_name,
      gate_type: val.gate_type,
      status: 1,
    };
    if (gateList.length === 0) {
      setGateList([gate]);
    } else {
      setGateList((prev) => [...prev, gate]);
    }
  };

  const updateSite = async (data) => {
    const body = {
      address: data.address,
      card_version: data.card_version,
      city: data.city,
      district: data.district,
      gate_version: data.gate_version,
      name: data.name,
      province: data.province,
      regency: data.regency,
      site_version: data.site_version,
      sv: data.sv,
      total_card: data.total_card,
      total_gate: data.total_gate,
      urban_village: data.urban_village,
    };
    ApiClient()
      .patch(`/sites/${data.sid}`, body)
      .then((res) => {
        openNotificationWithIcon("success", "Update Site", res.message);
        if (gateList.length > 0) {
          gateList.forEach(async (datas) => {
            datas.sid = data.sid;
            await postGate(datas);
          });
        }
        setIsModalSiteOpen(false);
        getSiteList();

        // console.log(res.data);
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          "Update Site",
          err.response.data.message
        );
        // console.log(err);
      });
    // await axios
    //   .patch(`${config.BASEURL}/sites/${data.sid}`, body, config.HEADERS)
    //   .then((res) => {
    //     openNotificationWithIcon("success", "Update Site", res.data.message);
    //     if (gateList.length > 0) {
    //       gateList.forEach(async (datas) => {
    //         datas.sid = data.sid;
    //         await postGate(datas);
    //       });
    //     }
    //     setIsModalSiteOpen(false);
    //     getSiteList();

    //     // console.log(res.data);
    //   })
    //   .catch((err) => {
    //     openNotificationWithIcon(
    //       "error",
    //       "Update Site",
    //       err.response.data.message
    //     );
    //     // console.log(err);
    //   });
    // .finally(() => {
    //   if (gateList.length > 0) {
    //     gateList.forEach(async (datas) => {
    //       datas.sid = data.sid;
    //       await postGate(datas);
    //     });
    //   }
    //   setIsModalSiteOpen(false);
    // });
  };

  const postGate = async (data) => {
    // console.log(data);
    const body = {
      sid: data.sid,
      name: data.gate_name,
      type: data.gate_type,
      status: data.status,
    };
    ApiClient()
      .post(`/gates/create`, body)
      .then((res) => {
        openNotificationWithIcon("success", "Create Gate", res.message);
        getSiteList();
        // console.log(res);
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          "Create Gate",
          err.response.data.message
        );
        // console.log(err);
      });
    // await axios
    //   .post(`${config.BASEURL}/gates/create`, body, config.HEADERS)
    //   .then((res) => {
    //     openNotificationWithIcon("success", "Create Gate", res.data.message);
    //     getSiteList();
    //     // console.log(res);
    //   })
    //   .catch((err) => {
    //     openNotificationWithIcon(
    //       "error",
    //       "Create Gate",
    //       err.response.data.message
    //     );
    //     // console.log(err);
    //   });
  };

  useEffect(() => {
    // console.log(record);
    if (record) {
      form.setFieldsValue(record);
    }
  }, [record]);
  const handleFormField = (value) => {
    // console.log(data);
    // form.setFieldsValue(value);
  };

  useEffect(() => {
    // console.log(gateList);
  }, [gateList]);

  const deleteGate = (data) => {
    const tmpArr = [...gateList];
    const idx = tmpArr.indexOf(data);
    tmpArr.splice(idx, 1);
    setGateList(tmpArr);
  };

  const columns = [
    {
      title: "Gate Name",
      dataIndex: "gate_name",
      key: "gate_name",
      width: "150px",
    },
    {
      title: "Gate Type",
      dataIndex: "gate_type",
      key: "gate_type",

      width: "150px",
    },
    {
      title: "Status",
      key: "status",
      align: "center",
      width: "100px",
      render: (_, record) => {
        // console.log(record);
        return (
          <Space size="middle">
            <Switch
              disabled={true}
              defaultChecked
              checked={record.status}
              onChange={() => {
                // updateStatus(record);
              }}
            />
          </Space>
        );
      },
    },
    {
      title: "Delete",
      key: "delete",
      align: "center",
      width: "100px",
      render: (_, record) => {
        // console.log(record);
        return (
          <Space size="middle">
            <Popconfirm
              title="Delete the gate"
              description="Are you sure to delete this gate?"
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              onConfirm={() => {
                deleteGate(record);
              }}
            >
              <Button danger>Delete</Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
  return (
    <Modal
      forceRender
      width="800px"
      title={
        <Typography.Title
          style={{
            color: "white",
            fontSize: 24,
            paddingLeft: 20,
          }}
        >
          Site Edit
        </Typography.Title>
      }
      // title="Site Edit"
      open={isModalSiteOpen}
      onOk={() => {
        updateSite(form.getFieldValue());
        // setEditForm(true);
      }}
      onCancel={() => {
        // setEditForm(false);
        // form.resetFields();
        setGateList([]);
        setIsModalSiteOpen(false);
      }}
      footer={[
        <Space key="spacebutton">
          <button
            key="printbutton"
            className="save-button"
            onClick={() => {
              updateSite(form.getFieldValue());

              // hanldePrint(record);
            }}
          >
            Update
          </button>
          <button
            key="closebutton"
            className="cancel-button"
            onClick={() => {
              setGateList([]);
              setIsModalSiteOpen(false);
              // setIsModalOpen(false);
            }}
          >
            CANCEL
          </button>
        </Space>,
        // <Button
        //   key="print"
        //   type="primary"
        //   onClick={() => {
        //     hanldePrint(record);
        //   }}
        // >
        //   Print
        // </Button>,
        // <Button
        //   key="close"
        //   onClick={() => {
        //     setIsModalOpen(false);
        //   }}
        // >
        //   Close
        // </Button>,
        // <Button key="link" href="https://google.com" type="primary">
        //   Search on Google
        // </Button>,
      ]}
    >
      <Form
        form={form}
        // name="site_edit"
        // onFinish={addSite}
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 15,
        }}
        layout="horizontal"
        autoComplete="off"

        // labelWrap={true}
      >
        <Form.Item
          label="Site ID"
          name="sid"
          // initialValue={record.sid}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input disabled={true} />
        </Form.Item>
        <Form.Item
          label="Site Name"
          name="name"
          // initialValue={record.name}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Site Version"
          name="site_version"
          // initialValue={record.site_version}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Site Value"
          name="sv"
          // initialValue={record.sv}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber />
        </Form.Item>

        <Form.Item
          label="Site Address"
          name="address"
          // initialValue={record.address}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Urban Village"
          name="urban_village"
          // initialValue={record.urban_village}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="District"
          name="district"
          // initialValue={record.district}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Regency"
          name="regency"
          // initialValue={record.regency}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Province"
          name="province"
          // initialValue={record.province}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="City"
          name="city"
          // initialValue={record.city}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        {/* <Row align="middle" style={{ justifyContent: "center" }}>
          <Col span={12}>
            <Form.Item
              label="Card Version"
              name="card_version"
              // initialValue={record.card_version}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Total Card"
              name="total_card"
              // initialValue={record.total_card}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber />
            </Form.Item>
          </Col>
        </Row> 
        */}
        <Form.Item
          label="Gate Version"
          name="gate_version"
          // initialValue={record.gate_version}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input width={1} />
        </Form.Item>

        <Form.Item
          label="Gate Name"
          name="gate_name"
          rules={[{ required: true, message: "Gate Name is required" }]}
        >
          <Input style={{ maxWidth: 300 }}></Input>
        </Form.Item>

        <Form.Item
          initialValue="gate in"
          label="Gate Type"

          // style={{ justifyContent: "space-between" }}
        >
          <Space size="large">
            <Form.Item
              name="gate_type"
              rules={[{ required: true, message: "Gate Type is required" }]}
              noStyle
            >
              <Select
                style={{ width: 150 }}
                options={[
                  { value: "IN", label: "GATE IN" },
                  { value: "OUT", label: "GATE OUT" },
                ]}
              />
            </Form.Item>

            <button
              className="add-button"
              onClick={() => {
                addSite(form.getFieldsValue());
              }}
            >
              ADD GATE
            </button>
            {/* <Button type="primary" htmlType="submit">
              Add Gate
            </Button> */}
          </Space>
        </Form.Item>
        <Table
          pagination={{ pageSize: 4 }}
          columns={columns}
          dataSource={gateList}
          rowKey={(record) => {
            return record.id;
          }}
        />
      </Form>
    </Modal>
  );
}

export default FormSiteEdit;
