import { Form, Input, Modal, Space, Typography } from "antd";
import React from "react";
import { ApiClient } from "../../api/ApiClient";

function FormForgotPassword({
  setIsModalResetPassword,
  isModalResetPassword,
  openNotificationWithIcon,
}) {
  const [form] = Form.useForm();

  const postTicket = async (data) => {
    if (data.email === "abc@gmail.com" || data.wa_number === "08xxxxxxxx") {
      openNotificationWithIcon(
        "info",
        "Info Message",
        "Please Change E-mail OR Whatsapp Number!"
      );
    } else {
      const ticket = {
        detail: data.wa_number,
        problem: "Reset Password User",
        reported_by: data.email,
      };
      ApiClient()
        .post("/support/create", ticket)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <Modal
      open={isModalResetPassword}
      onOk={() => {}}
      onCancel={() => {
        setIsModalResetPassword(false);
      }}
      title={
        <Typography.Title
          style={{
            color: "white",
            fontSize: 24,
            paddingLeft: 20,
          }}
        >
          Forgot Password
        </Typography.Title>
      }
      footer={[
        <Space size="middle" key="space-button">
          <button
            key="cancelbutton"
            className="cancel-button"
            onClick={() => {
              setIsModalResetPassword(false);
            }}
          >
            CANCEL
          </button>
          <button
            key="savebutton"
            className="save-button"
            onClick={() => {
              postTicket(form.getFieldsValue());
              // setIsModalResetPassword(false);
            }}
          >
            SEND
          </button>
          ,
        </Space>,
      ]}
    >
      {/* <Typography.Text>CONTENT IS NOT AVAILABLE</Typography.Text> */}
      <Form
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 12,
        }}
        form={form}
        // onFinish={updatePassword}
        layout="horizontal"
      >
        <Form.Item
          label="E-mail"
          name="email"
          initialValue="abc@gmail.com"
          rules={[
            {
              required: true,
              message: "Please input your E-mail!",
            },
          ]}
        >
          <Input style={{ width: 200 }} />
        </Form.Item>
        <Form.Item
          label="WhatsApp Number"
          name="wa_number"
          initialValue="08xxxxxxxx"
          rules={[
            {
              required: true,
              message: "Please input your WhatsApp Number!",
            },
          ]}
        >
          <Input style={{ width: 200 }} />
        </Form.Item>
        {/* <Form.Item
          label="Confirm New Password"
          name="confirm_new_password"
          rules={[
            {
              required: true,
              message: "Please input your New Password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("new_password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The new password that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password style={{ width: 200 }} />
        </Form.Item> */}
      </Form>
    </Modal>
  );
}

export default FormForgotPassword;
