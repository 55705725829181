import React, { useEffect, useState } from "react";
import { Form, Input, Select, Modal, Typography, Space } from "antd";

import { ApiClient } from "../../api/ApiClient";

function FormUserRegistration({
  setModalUserRegistration,
  modalUserRegistration,
  getUserList,
  openNotificationWithIcon,
}) {
  const [verifyPassword, setVerifyPassword] = useState("");
  const [allSite, setAllSite] = useState([]);
  const [allRole, setAllRole] = useState([]);
  const [form] = Form.useForm();

  const getSiteList = async () => {
    ApiClient()
      .get(`/sites?limit=100&detail=1`)
      .then((res) => {
        var tmp = [];
        res.data.forEach((data) => {
          // console.log(data);
          if (localStorage.getItem("credent") === "4") {
            tmp.push({ label: data.name, value: data.sid });
          } else {
            if (data.sid == localStorage.getItem("sid")) {
              tmp.push({ label: data.name, value: data.sid });
            }
          }
        });
        setAllSite(tmp);
      })
      .catch((err) => {
        console.log(err);
      });
    // await axios
    //   .get(`${config.BASEURL}/sites?limit=100&detail=1`, config.HEADERS)
    //   .then((res) => {
    //     var tmp = [];
    //     res.data.data.forEach((data) => {
    //       // console.log(data);
    //       tmp.push({ label: data.name, value: data.sid });
    //     });
    //     setAllSite(tmp);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  const getRoleList = async () => {
    ApiClient()
      .get(`/roles`)
      .then((res) => {
        var tmp = [];
        res.data.forEach((data) => {
          if (
            data.rv !== 4 &&
            data.rv !== 3 &&
            data.rv !== 0 &&
            localStorage.getItem("credent") === "3"
          ) {
            tmp.push({ label: data.name.toUpperCase(), value: data.rid });
          } else if (
            data.rv !== 4 &&
            data.rv !== 0 &&
            localStorage.getItem("credent") === "4"
          ) {
            tmp.push({ label: data.name.toUpperCase(), value: data.rid });
          }
        });
        setAllRole(tmp);
      })
      .catch((err) => {
        console.log(err);
      });
    // await axios
    //   .get(`${config.BASEURL}/roles`, config.HEADERS)
    //   .then((res) => {
    //     var tmp = [];
    //     res.data.data.forEach((data) => {
    //       if (
    //         data.rv !== 4 &&
    //         data.rv !== 3 &&
    //         data.rv !== 0 &&
    //         localStorage.getItem("credent") === "3"
    //       ) {
    //         tmp.push({ label: data.name.toUpperCase(), value: data.rid });
    //       } else if (
    //         data.rv !== 4 &&
    //         data.rv !== 0 &&
    //         localStorage.getItem("credent") === "4"
    //       ) {
    //         tmp.push({ label: data.name.toUpperCase(), value: data.rid });
    //       }
    //     });
    //     setAllRole(tmp);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  const postUser = async (data) => {
    const body = {
      email: data.new_email,
      password: data.password,
      rid: data.rid,
      sid: data.sid,
      username: data.new_username,
    };
    ApiClient()
      .post(`/users/create`, body)
      .then((res) => {
        openNotificationWithIcon("success", "Create User", res.message);

        form.resetFields();
        setModalUserRegistration(false);
        getUserList();
      })
      .then((err) => {
        // openNotificationWithIcon(
        //   "error",
        //   "Create User",
        //   err.response.data.message
        // );
        console.log(err);
      });
    // await axios
    //   .post(`${config.BASEURL}/users/create`, body, config.HEADERS)
    //   .then((res) => {
    //     openNotificationWithIcon("success", "Create User", res.data.message);

    //     form.resetFields();
    //     setModalUserRegistration(false);
    //     getUserList();
    //   })
    //   .then((err) => {
    //     // openNotificationWithIcon(
    //     //   "error",
    //     //   "Create User",
    //     //   err.response.data.message
    //     // );
    //     console.log(err);
    //   });
  };

  useEffect(() => {
    getSiteList();
    if (localStorage.getItem("credent") === "4") {
      getRoleList();
    } else {
      setAllRole([
        {
          label: "USER",
          value: "2ba9dcf4-06e5-4a98-a5d3-907027bf1ee7",
        },
      ]);
    }

    return () => {
      // getSiteList();
      // getRoleList();
    };
  }, []);

  return (
    <Modal
      forceRender
      title={
        <Typography.Title
          style={{
            color: "white",
            fontSize: 24,
            paddingLeft: 20,
          }}
        >
          User Registration
        </Typography.Title>
      }
      // title="User Registration"
      open={modalUserRegistration}
      width={700}
      onCancel={() => {
        form.resetFields();
        setModalUserRegistration(false);
      }}
      // onOk={() => {
      //   // setModalUserRegistration(false);
      //   var errorCheck = true;
      //   form
      //     .validateFields()
      //     .then(() => {
      //       form.getFieldsError().forEach((data) => {
      //         if (data.errors.length > 0) {
      //           errorCheck = false;
      //         }
      //         console.log(data);
      //       });
      //       if (errorCheck) {
      //         postUser(form.getFieldValue());
      //       }
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //     });

      //   // console.log(errorCheck);
      //   //
      // }}
      footer={[
        <Space key="spacebutton">
          <button
            key="printbutton"
            className="save-button"
            onClick={() => {
              var errorCheck = true;
              form
                .validateFields()
                .then(() => {
                  form.getFieldsError().forEach((data) => {
                    if (data.errors.length > 0) {
                      errorCheck = false;
                    }
                    console.log(data);
                  });
                  if (errorCheck) {
                    postUser(form.getFieldValue());
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
              // hanldePrint(record);
            }}
          >
            ADD
          </button>
          <button
            key="closebutton"
            className="cancel-button"
            onClick={() => {
              form.resetFields();
              setModalUserRegistration(false);
              // setIsModalOpen(false);
            }}
          >
            CANCEL
          </button>
        </Space>,
        // <Button
        //   key="print"
        //   type="primary"
        //   onClick={() => {
        //     hanldePrint(record);
        //   }}
        // >
        //   Print
        // </Button>,
        // <Button
        //   key="close"
        //   onClick={() => {
        //     setIsModalOpen(false);
        //   }}
        // >
        //   Close
        // </Button>,
        // <Button key="link" href="https://google.com" type="primary">
        //   Search on Google
        // </Button>,
      ]}
    >
      <Form
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 10,
        }}
        form={form}
        layout="horizontal"
        style={{ paddingRight: "15px", paddingLeft: "15px" }}
        autoComplete="off"
      >
        <Form.Item
          label="Select Site"
          name="sid"
          rules={[
            {
              required: true,
              message: "Site is required",
            },
          ]}
        >
          <Select style={{ maxWidth: "300px" }} options={allSite}></Select>
        </Form.Item>
        <Form.Item
          label="Select Role"
          name="rid"
          rules={[
            {
              required: true,
              message: "Role is required",
            },
          ]}
        >
          <Select style={{ maxWidth: "300px" }} options={allRole}></Select>
        </Form.Item>
        <Form.Item label="Full Name" name="fullname">
          <Input />
        </Form.Item>
        <Form.Item label="Address" name="new_address">
          <Input />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: "E-mail is required",
            },
          ]}
          label="E-mail"
          name="new_email"
        >
          <Input />
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
              message: "Username is required",
            },
          ]}
          label="Username"
          name="new_username"
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "Password is required" }]}
          label="Password"
          name="password"
        >
          <Input.Password
            style={{ maxWidth: 300 }}
            placeholder="input password"
            onChange={(e) => {
              setVerifyPassword(e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The new password that you entered do not match!")
                );
              },
            }),
          ]}
          label="Verify Password"
          name="verify-password"
          dependencies={["password"]}
        >
          <Input.Password
            style={{ maxWidth: 300 }}
            placeholder="input password"
            onChange={(e) => {
              if (e.target.value !== verifyPassword) {
                console.log("first");
              }
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default FormUserRegistration;
