const config = {
  BASEURL: "https://portalock.devtbn.tech/api/v1",
  SOCKET_URL: "wss://portalock.devtbn.tech/wss/",
  TOKEN_KEY: "redPelpet",
  ROLE_NAME: "2",
  HEADERS: {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      Accept: "application/json",
    },
  },
};

export default config;
