import React, { useEffect } from "react";
import { Form, Input, Select, Modal, Typography, Space } from "antd";
import { ApiClient } from "../../api/ApiClient";

function FormGateEdit({
  data,
  isModalGateEdit,
  setIsModalGateEdit,
  getSiteList,
  openNotificationWithIcon,
}) {
  const [form] = Form.useForm();

  const updateGate = async (data) => {
    // console.log(data);
    const body = {
      sid: data.sid,
      name: data.name,
      type: data.type,
    };

    ApiClient()
      .patch(`/gates/${data.gid}`, body)
      .then((res) => {
        openNotificationWithIcon("success", "Update Gate", res.message);

        // console.log(res.data.data);
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          "Update Status",
          err.response.data.message
        );
        // console.log(err.data);
      })
      .finally((res) => {
        // console.log(res);
        getSiteList();
      });
    // await axios
    //   .patch(`${config.BASEURL}/gates/${data.gid}`, body, config.HEADERS)
    //   .then((res) => {
    //     console.log(res.data.data);
    //   })
    //   .catch((err) => {
    //     console.log(err.data);
    //   })
    //   .finally((res) => {
    //     console.log(res);
    //     getSiteList();
    //   });
  };

  useEffect(() => {
    form.setFieldsValue(data);

    return () => form.setFieldsValue(data);
  }, [data]);

  return (
    <Modal
      forceRender
      width="800px"
      title={
        <Typography.Title
          style={{
            color: "white",
            fontSize: 24,
            paddingLeft: 20,
          }}
        >
          Gate Edit
        </Typography.Title>
      }
      // title="Gate Edit"
      open={isModalGateEdit}
      onOk={() => {
        updateGate(form.getFieldsValue());
        setIsModalGateEdit(false);
      }}
      onCancel={() => {
        setIsModalGateEdit(false);
      }}
      footer={[
        <Space key="spacebutton">
          <button
            key="printbutton"
            className="save-button"
            onClick={() => {
              updateGate(form.getFieldsValue());
              setIsModalGateEdit(false);
            }}
          >
            Update
          </button>
          <button
            key="closebutton"
            className="cancel-button"
            onClick={() => {
              setIsModalGateEdit(false);

              // setIsModalOpen(false);
            }}
          >
            CANCEL
          </button>
        </Space>,
      ]}
    >
      <Form
        form={form}
        // name="site_edit"
        // onFinish={addSite}
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 15,
        }}
        layout="horizontal"
        autoComplete="off"
      >
        <Form.Item label="Site ID" name="sid" rules={[{ required: true }]}>
          <Input disabled={true} />
        </Form.Item>
        <Form.Item label="Gate ID" name="gid">
          <Input disabled={true} />
        </Form.Item>
        <Form.Item label="Gate Name" name="name">
          <Input />
        </Form.Item>
        <Form.Item label="Gate Type" name="type">
          <Select
            // defaultValue={}
            style={{ width: 150 }}
            options={[
              { value: "IN", label: "GATE IN" },
              { value: "OUT", label: "GATE OUT" },
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default FormGateEdit;
