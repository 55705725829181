import React, { useState, useMemo } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import Page404 from "../pages/error/Page404";

// import DashboardPage from "../pages/Dashboard";
import HomePage from "../pages/HomePage";
import LoginPage from "../pages/LoginPage";
import Log from "../pages/logs/LogPage";
import CardListPage from "../pages/cards/CardListPage";
import UserListPage from "../pages/users/UserListPage";
import SiteListPage from "../pages/sites/SiteListPage";
import Sidebar from "../components/shared/Sidebar";
import Page401 from "../pages/error/Page401";
import config from "../config/global";
import useWebSocket from "react-use-websocket";
import InfoUser from "../pages/info/InfoUser";
import TicketListPage from "../pages/ticket/TicketListPage";

function IndexRoutes() {
  const token = localStorage.getItem("token");
  const urlSOCK = config.SOCKET_URL + localStorage.getItem("uid");
  const [eventSocket, setEventSocket] = useState("");

  // console.log(urlSOCK);
  // console.log("first");
  const options = useMemo(
    () => ({
      // share: true,
      onMessage: (message) => {
        setEventSocket(message.data);
      },
      onClose: (event) => console.log("onClose", event),
      onError: (error) => console.log("onError", error),
      onOpen: (event) => console.log("onOpen", event),
      retryOnError: true,
      // fromSocketIO: true,
    }),
    []
  );

  const { sendMessage, lastMessage, readyState } = useWebSocket(
    urlSOCK,
    options
  );
  // console.log(readyState);

  // const { sendMessage, lastMessage, readyState } = useWebSocket(
  //   config.SOCKET_URL,
  //   {
  //     // share: true,
  //     onMessage: (message) =>
  //       console.log(
  //         `onMessage with access to dynamicProp: ${dynamicPropRef.current}`,
  //         message
  //       ),
  //     onClose: (event) => console.log("onClose", event),
  //     onError: (error) => console.log("onError", error),
  //     onOpen: (event) => console.log("onOpen", event),
  //     // fromSocketIO: true,
  //   }
  // );

  return (
    <div>
      <BrowserRouter>
        {token ? (
          <Sidebar>
            <Routes>
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <HomePage socketEvent={eventSocket} />
                  </ProtectedRoute>
                }
              />

              {/* <Route
                path="/card-registration"
                element={
                  <ProtectedRoute>
                    <CardRegistration />
                  </ProtectedRoute>
                }
              /> */}
              <Route
                path="/account-list"
                element={
                  <ProtectedRoute>
                    <CardListPage />
                  </ProtectedRoute>
                }
              />

              {/* <Route
                path="/user-registration"
                element={
                  <ProtectedRoute>
                    <UserRegistration />
                  </ProtectedRoute>
                }
              /> */}
              <Route
                path="/user-list"
                element={
                  <ProtectedRoute>
                    <UserListPage />
                  </ProtectedRoute>
                }
              />

              {/* <Route
                path="/site-registration"
                element={
                  <ProtectedRoute>
                    <SiteRegistrationPage />
                  </ProtectedRoute>
                }
              /> */}
              <Route
                path="/site-list"
                element={
                  <ProtectedRoute>
                    <SiteListPage />
                  </ProtectedRoute>
                }
              />

              {/* <Route
                path="/gate-registration"
                element={
                  <ProtectedRoute>
                    <GateRegistrationPage />
                  </ProtectedRoute>
                }
              /> */}
              {/* <Route
                path="/gate-list"
                element={
                  <ProtectedRoute>
                    <GateListPage />
                  </ProtectedRoute>
                }
              /> */}

              <Route
                path="/log"
                element={
                  <ProtectedRoute>
                    <Log socketEvent={eventSocket} />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/user-info"
                element={
                  <ProtectedRoute>
                    <InfoUser />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/ticket-list"
                element={
                  <ProtectedRoute>
                    <TicketListPage />
                  </ProtectedRoute>
                }
              />
              <Route path="*" element={<Page404 />} />
            </Routes>
          </Sidebar>
        ) : (
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/401" element={<Page401 />} />
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <HomePage />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<Page404 />} />
          </Routes>
        )}
      </BrowserRouter>
    </div>
  );
}

export default IndexRoutes;
