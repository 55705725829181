import { Form, Modal, Input, Typography, Space } from "antd";
import React, { useEffect, useState } from "react";
import { ApiClient } from "../../api/ApiClient";

function FormResetPassword({
  data,
  isModalResetPassword,
  setIsModalResetPassword,
  openNotificationWithIcon,
  postSolveTicket,
}) {
  const [form] = Form.useForm();

  const postForceResetPassword = (user) => {
    const pwd = {
      new_credential: user.new_password.new_password,
    };
    // console.log(user);

    ApiClient()
      .patch(`/users/${user.uid}/force`, pwd)
      .then((res) => {
        openNotificationWithIcon("success", "Update Set Password", res.message);
        postSolveTicket({
          condition: "Solved",
          ticket: user.ticket.ticket,
        });

        setIsModalResetPassword(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    form.resetFields();
  });

  return (
    <Modal
      forceRender
      width="500px"
      // title="Reset Password"

      title={
        <Typography.Title
          style={{
            color: "white",
            fontSize: 24,
            paddingLeft: 20,
          }}
        >
          Reset Password
        </Typography.Title>
      }
      open={isModalResetPassword}
      // onOk={() => {
      //   data.new_password = form.getFieldsValue();
      //   postForceResetPassword(data);
      // }}
      onCancel={() => {
        setIsModalResetPassword(false);
        // setIsModalGateEdit(false);
      }}
      footer={[
        <Space key="spacebutton">
          <button
            key="printbutton"
            className="save-button"
            onClick={() => {
              data.new_password = form.getFieldsValue();
              postForceResetPassword(data);
            }}
          >
            Update
          </button>
          <button
            key="closebutton"
            className="cancel-button"
            onClick={() => {
              // setIsModalGateEdit(false);
              setIsModalResetPassword(false);

              // setIsModalOpen(false);
            }}
          >
            CANCEL
          </button>
        </Space>,
      ]}
    >
      <Form
        form={form}
        // name="site_edit"
        // onFinish={addSite}
        labelCol={{
          span: 10,
        }}
        wrapperCol={{
          span: 12,
        }}
        layout="horizontal"
        autoComplete="off"
      >
        <Form.Item
          label="New Password"
          name="new_password"
          rules={[
            {
              required: true,
              message: "Please input your New Password!",
            },
          ]}
        >
          <Input.Password style={{ width: 200 }} />
        </Form.Item>
        <Form.Item
          label="Confirm New Password"
          name="confirm_new_password"
          rules={[
            {
              required: true,
              message: "Please input your New Password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("new_password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The new password that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password style={{ width: 200 }} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default FormResetPassword;
