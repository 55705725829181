import { SearchOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import React, { useRef, useState, useEffect } from "react";
import Highlighter from "react-highlight-words";
import {
  Button,
  Input,
  Row,
  Space,
  Table,
  Switch,
  Popconfirm,
  Modal,
  notification,
  ConfigProvider,
  Skeleton,
  Typography,
} from "antd";
import axios from "axios";
import config from "../../config/global";
import FormSiteRegistration from "./FormSiteRegistration";
import FormSiteEdit from "./FormSiteEdit";
import FormGateEdit from "./FormGateEdit";
import "../../../src/App.css";
import { ApiClient } from "../../api/ApiClient";

function TableSite() {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [isModalSiteOpen, setIsModalSiteOpen] = useState(false);
  const [isModalAddSite, setIsModalAddSite] = useState(false);
  const [isModalGateEdit, setIsModalGateEdit] = useState(false);
  const [buttonModalDisabled, setButtonModalDisabled] = useState(true);
  const [clearForm, setClearForm] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const searchInput = useRef(null);

  const [dataRecord, setDataRecord] = useState({});
  const [gateDataRecord, setGateDataRecord] = useState({});
  const [allSite, setAllSite] = useState([]);
  const [addSite, setAddSite] = useState([]);

  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type, message, description) => {
    // console.log(type, message, description);
    api[type]({
      message: message,
      description: description,
    });
  };

  const getSiteList = async () => {
    ApiClient()
      .get(`/sites/gates?detail=1&refresh=true`)
      .then((res) => {
        setAllSite(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    // await axios
    //   .get(
    //     `${config.BASEURL}/sites/gates?detail=1&refresh=true`,
    //     config.HEADERS
    //   )
    //   .then((res) => {
    //     setAllSite(res.data.data);
    //     setIsLoading(false);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  const updateSiteStatus = async (data) => {
    ApiClient()
      .patch(`/sites/${data.sid}`, {
        status: Number(!data.status),
      })
      .then((res) => {
        openNotificationWithIcon("success", "Update Status", res.message);
        // console.log(res.data);
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          "Update Status",
          err.response.data.message
        );
      })
      .finally(() => {
        getSiteList();
      });
    // await axios
    //   .patch(
    //     `${config.BASEURL}/sites/${data.sid}`,
    //     {
    //       status: Number(!data.status),
    //     },
    //     config.HEADERS
    //   )
    //   .then((res) => {
    //     openNotificationWithIcon("success", "Update Status", res.data.message);
    //     // console.log(res.data);
    //   })
    //   .catch((err) => {
    //     openNotificationWithIcon(
    //       "error",
    //       "Update Status",
    //       err.response.data.message
    //     );
    //   })
    //   .finally(() => {
    //     getSiteList();
    //   });
  };

  const updateGateStatus = async (data) => {
    ApiClient()
      .patch(`/gates/${data.gid}`, {
        sid: data.sid,
        status: Number(!data.status),
      })
      .then((res) => {
        openNotificationWithIcon("success", "Update Status", res.message);
        // console.log(res.data);
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          "Update Status",
          err.response.data.message
        );
      })
      .finally(() => {
        getSiteList();
      });
    // await axios
    //   .patch(
    //     `${config.BASEURL}/gates/${data.gid}`,
    //     {
    //       sid: data.sid,
    //       status: Number(!data.status),
    //     },
    //     config.HEADERS
    //   )
    //   .then((res) => {
    //     openNotificationWithIcon("success", "Update Status", res.data.message);
    //     // console.log(res.data);
    //   })
    //   .catch((err) => {
    //     openNotificationWithIcon(
    //       "error",
    //       "Update Status",
    //       err.response.data.message
    //     );
    //   })
    //   .finally(() => {
    //     getSiteList();
    //   });
  };

  const postSite = async (data) => {
    const body = {
      address: data.site.address,
      card_version: "0",
      city: data.site.city,
      created_by: "Admin",
      district: data.site.district,
      gate_version: "0",
      name: data.site.site_name,
      province: data.site.province,
      regency: data.site.regency,
      site_version: "0",
      total_card: 0,
      total_gate: 0,
      updated_by: null,
      urban_village: data.site.urban_village,
      status: 1,
    };

    ApiClient()
      .post(`/sites/create`, body)
      .then((res) => {
        openNotificationWithIcon("success", "Create Site", res.message);

        data.gate.forEach(async (data) => {
          data.sid = res.data.sid;
          await postGates(data);
        });
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          "Create Site",
          err.response.data.message
        );

        // console.log(err);
      });
    // await axios
    //   .post(`${config.BASEURL}/sites/create`, body, config.HEADERS)
    //   .then((res) => {
    //     openNotificationWithIcon("success", "Create Site", res.data.message);

    //     data.gate.forEach(async (data) => {
    //       data.sid = res.data.data.sid;
    //       await postGates(data);
    //     });
    //   })
    //   .catch((err) => {
    //     openNotificationWithIcon(
    //       "error",
    //       "Create Site",
    //       err.response.data.message
    //     );

    //     // console.log(err);
    //   });
  };
  const postGates = async (data) => {
    const body = {
      name: data.gate_name,
      sid: data.sid,
      type: data.gate_type,
      version: "0",
    };

    ApiClient()
      .post(`/gates/create`, body)
      .then((res) => {
        openNotificationWithIcon("success", "Create Gate", res.message);
        getSiteList();
        // console.log(res.data.data);
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          "Create Gate",
          err.response.data.message
        );
        // console.log(err.data);
      });
    // await axios
    //   .post(`${config.BASEURL}/gates/create`, body, config.HEADERS)
    //   .then((res) => {
    //     openNotificationWithIcon("success", "Create Gate", res.data.message);
    //     getSiteList();
    //     // console.log(res.data.data);
    //   })
    //   .catch((err) => {
    //     openNotificationWithIcon(
    //       "error",
    //       "Create Gate",
    //       err.response.data.message
    //     );
    //     // console.log(err.data);
    //   });
  };

  const deleteSite = async (data) => {
    ApiClient()
      .del(`/sites/${data.sid}`)
      .then((res) => {
        openNotificationWithIcon("success", "Delete Site", res.message);

        // console.log(res.data);
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          "Delete Site",
          err.response.data.message
        );

        // console.log(err);
      })
      .finally(() => {
        getSiteList();
      });
    // await axios
    //   .delete(`${config.BASEURL}/sites/${data.sid}`, config.HEADERS)
    //   .then((res) => {
    //     openNotificationWithIcon("success", "Delete Site", res.data.message);

    //     // console.log(res.data);
    //   })
    //   .catch((err) => {
    //     openNotificationWithIcon(
    //       "error",
    //       "Delete Site",
    //       err.response.data.message
    //     );

    //     // console.log(err);
    //   })
    //   .finally(() => {
    //     getSiteList();
    //   });
  };

  const deleteGate = async (data) => {
    ApiClient()
      .del(`/gates/${data.gid}`)
      .then((res) => {
        openNotificationWithIcon("success", "Delete Gate", res.message);

        // console.log(res.data);
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          "Delete Gate",
          err.response.data.message
        );

        // console.log(err);
      })
      .finally(() => {
        getSiteList();
      });
    // await axios
    //   .delete(`${config.BASEURL}/gates/${data.gid}`, config.HEADERS)
    //   .then((res) => {
    //     openNotificationWithIcon("success", "Delete Gate", res.data.message);

    //     // console.log(res.data);
    //   })
    //   .catch((err) => {
    //     openNotificationWithIcon(
    //       "error",
    //       "Delete Gate",
    //       err.response.data.message
    //     );

    //     // console.log(err);
    //   })
    //   .finally(() => {
    //     getSiteList();
    //   });
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#DAFFFB" : "white",
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: "Site ID",
      dataIndex: "sid",
      key: "sid",
      fixed: "left",
      width: "200px",
      sorter: (a, b) => {
        return a.ID - b.ID;
      }, // (a, b) => a.card_uid.length - b.card_uid.length,
      defaultSortOrder: "ascend",
      sortDirections: ["descend", "ascend"],
      // ...getColumnSearchProps("sid"),
    },
    {
      title: "Site Name",
      dataIndex: "name",
      key: "name",
      width: "200px",
      ...getColumnSearchProps("name"),
      fixed: "left",
    },

    {
      title: "Site Address",
      dataIndex: "address",
      key: "address",
      width: "200px",
      ...getColumnSearchProps("address"),
    },
    {
      title: "Urban Village",
      dataIndex: "district",
      key: "district",
      width: "200px",
      ...getColumnSearchProps("district"),
    },
    {
      title: "District",
      dataIndex: "district",
      key: "district",
      width: "200px",
      ...getColumnSearchProps("district"),
    },
    {
      title: "Regency",
      dataIndex: "regency",
      key: "regency",
      width: "200px",
      ...getColumnSearchProps("regency"),
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      width: "200px",
      ...getColumnSearchProps("city"),
    },
    {
      title: "Province",
      dataIndex: "province",
      key: "province",
      width: "200px",
      ...getColumnSearchProps("province"),
    },
    {
      title: "Total Gate",
      dataIndex: "total_gate",
      key: "total_gate",
      align: "center",
      width: "150px",
      ...getColumnSearchProps("total_gate"),
    },
    {
      title: "Total Card",
      dataIndex: "total_card",
      key: "total_card",
      align: "center",
      width: "150px",
      ...getColumnSearchProps("total_card"),
    },
    {
      title: "Site Version",
      dataIndex: "site_version",
      key: "site_version",
      align: "center",
      width: "150px",
      ...getColumnSearchProps("site_version"),
    },
    {
      title: "Gate Version",
      dataIndex: "gate_version",
      key: "gate_version",
      align: "center",
      width: "150px",
      ...getColumnSearchProps("gate_version"),
    },
    {
      title: "Edit",
      key: "edit",
      align: "center",
      fixed: "right",
      width: "120px",
      render: (_, record) => {
        // console.log(record);
        return (
          <Space size="middle">
            {/* <Button
              type="primary"
              onClick={() => {
                openModal(record);

                // console.log(record);
              }}
            >
              Edit
            </Button> */}
            <button
              className="edit-button"
              onClick={() => {
                openModal(record);

                // console.log(record);
              }}
            >
              EDIT
            </button>
          </Space>
        );
      },
    },
    {
      title: "Status",
      key: "status",
      align: "center",
      fixed: "right",
      width: "100px",
      render: (_, record) => {
        // console.log(record);
        return (
          <Space size="middle">
            <Popconfirm
              title="Update status site"
              description="Are you sure to update this site?"
              icon={<QuestionCircleOutlined style={{ color: "blue" }} />}
              onConfirm={() => {
                updateSiteStatus(record);
                // deleteSite(record);
              }}
            >
              <Switch
                defaultChecked
                checked={record.status}
                // onChange={() => {
                //   updateSiteStatus(record);
                // }}
              />
            </Popconfirm>
          </Space>
        );
      },
    },
    {
      title: "Delete",
      key: "delete",
      align: "center",
      fixed: "right",
      width: "150px",
      render: (_, record) => {
        // console.log(record);
        return (
          <Space size="middle">
            <Popconfirm
              title="Delete site"
              description="Are you sure to delete this site?"
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              onConfirm={() => {
                deleteSite(record);
              }}
            >
              <button className="delete-button">DELETE</button>
              {/* <Button danger>Delete</Button> */}
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const expandedRowRender = (record) => {
    // console.log(record.status);
    const columns = [
      {
        title: "Gate ID",
        dataIndex: "gid",
        key: "gid",
        width: "200px",
        sorter: (a, b) => {
          return a.ID - b.ID;
        },
        defaultSortOrder: "ascend",
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Gate Name",
        dataIndex: "name",
        key: "name",
        width: "150px",
      },
      {
        title: "Gate Type",
        dataIndex: "type",
        key: "type",
        align: "center",
        width: "150px",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "state",
        width: "100px",
        align: "center",
        render: (_, record) => {
          // console.log(record);
          return (
            <Space size="middle">
              <Popconfirm
                title="Update status gate"
                description="Are you sure to update this gate?"
                icon={<QuestionCircleOutlined style={{ color: "blue" }} />}
                onConfirm={() => {
                  updateGateStatus(record);
                  // deleteSite(record);
                }}
              >
                <Switch
                  defaultChecked
                  checked={record.status}
                  // onChange={() => {

                  //   // updateStatus(record);
                  // }}
                />
              </Popconfirm>
            </Space>
          );
        },
      },
      {
        title: "Edit",
        dataIndex: "edit",
        key: "edit",
        align: "center",
        width: "100px",
        align: "center",
        render: (_, record) => (
          <Space size="middle">
            {/* <Button
              onClick={() => {
                setGateDataRecord(record);
                setIsModalGateEdit(true);
              }}
            >
              Edit
            </Button> */}
            <button
              className="edit-button"
              onClick={() => {
                setGateDataRecord(record);
                setIsModalGateEdit(true);
              }}
            >
              EDIT
            </button>
          </Space>
        ),
      },
      {
        title: "Delete",
        key: "delete",
        align: "center",
        width: "100px",
        align: "center",
        render: (_, record) => {
          // console.log(record);
          return (
            <Space size="middle">
              <Popconfirm
                title="Delete gate"
                description="Are you sure to delete this gate?"
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                onConfirm={() => {
                  deleteGate(record);
                }}
              >
                <button className="delete-button">DELETE</button>
                {/* <Button danger>Delete</Button> */}
              </Popconfirm>
            </Space>
          );
        },
      },
    ];

    return (
      <Table
        columns={columns}
        dataSource={record.gates}
        pagination={false}
        rowKey={(record) => {
          return record.gid;
        }}
      />
    );
  };

  useEffect(() => {
    setIsLoading(true);

    getSiteList();

    return () => {
      getSiteList();
    };
  }, []);

  // useEffect(() => {
  //   getSiteList();
  //   console.log("GATE EDIT", isModalGateEdit);
  // }, [isModalGateEdit, isModalSiteOpen]);

  const openModal = (record) => {
    setDataRecord(record);
    setIsModalSiteOpen(true);
  };

  return (
    <Row
      style={{
        display: "flow-root",
        paddingLeft: "15px",
        paddingRight: "15px",
      }}
    >
      {contextHolder}
      <Row
        align="bottom"
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          paddingBottom: "15px",
        }}
      >
        <button
          className="add-button"
          onClick={() => {
            setTimeout(() => {
              setButtonModalDisabled(true);
              setIsModalAddSite(true);
              setClearForm(true);
            }, 200);
          }}
        >
          ADD SITE
        </button>
        {/* <Button
          type="primary"
          onClick={() => {
            setButtonModalDisabled(true);
            setIsModalAddSite(true);
            setClearForm(true);
          }}
        >
          ADD SITE
        </Button> */}
      </Row>

      <Table
        columns={columns}
        dataSource={allSite}
        rowKey={(record) => {
          return record.sid;
        }}
        expandable={{
          expandedRowRender,
          defaultExpandedRowKeys: ["0"],
        }}
        scroll={{
          x: 1300,
        }}
        loading={isLoading}
        pagination={{
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} sites`,
          onChange: (e) => {
            // console.log(e);
          },
        }}
        // colorBgContainer="#1677ff"
      />

      <Modal
        forceRender
        open={isModalAddSite}
        title={
          <Typography.Title
            style={{
              color: "white",
              fontSize: 24,
              paddingLeft: 20,
            }}
          >
            Add Site
          </Typography.Title>
        }
        // title="ADD SITE"
        onCancel={() => {
          setIsModalAddSite(false);
        }}
        onOk={() => {
          // setOnOk(true);
          setIsModalAddSite(false);
          postSite(addSite);
          // console.log(addSite);
        }}
        footer={[
          <Space key="spacebutton">
            <button
              key="printbutton"
              className="add-button"
              disabled={buttonModalDisabled}
              onClick={() => {
                setIsModalAddSite(false);
                postSite(addSite);

                // hanldePrint(record);
              }}
            >
              ADD
            </button>
            <button
              key="closebutton"
              className="cancel-button"
              onClick={() => {
                setIsModalAddSite(false);

                // setIsModalOpen(false);
              }}
            >
              CANCEL
            </button>
          </Space>,
        ]}
        // okButtonProps={{ disabled: buttonModalDisabled }}
        width={600}
      >
        <FormSiteRegistration
          setAddSite={setAddSite}
          setButtonModalDisabled={setButtonModalDisabled}
          clearForm={clearForm}
          setClearForm={setClearForm}
        />
      </Modal>
      <FormSiteEdit
        record={dataRecord}
        isModalSiteOpen={isModalSiteOpen}
        setIsModalSiteOpen={setIsModalSiteOpen}
        getSiteList={getSiteList}
        openNotificationWithIcon={openNotificationWithIcon}
      />
      <FormGateEdit
        data={gateDataRecord}
        isModalGateEdit={isModalGateEdit}
        setIsModalGateEdit={setIsModalGateEdit}
        getSiteList={getSiteList}
        openNotificationWithIcon={openNotificationWithIcon}
      />
    </Row>
  );
}
export default TableSite;
