import React from "react";
import TableTicket from "../../components/admin/TableTicket";

function TicketListPage() {
  return (
    <div className="load-content">
      <TableTicket />
    </div>
  );
}

export default TicketListPage;
