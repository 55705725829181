import React, { useEffect } from "react";
import BasePage from "../shared/BasePage";
import { Col, Image, Row } from "antd";
// import Image from "react-bootstrap/Image";
import { useNavigate } from "react-router-dom";

function Page401() {
  const navigate = useNavigate();
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/");
    }, 5000);
    return () => clearTimeout(timer);
  }, [navigate]);
  return (
    <BasePage>
      <div
        style={{
          width: "100%",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundImage:
            "url(https://edge.mwallpapers.com/photos/celebrities/hd-wallpapers/simple-background-digital-art-textured-texture-android-iphone-hd-wallpaper-background-downloadhd-wallpapers-desktop-background-android-iphone-1080p-4k-glzox.jpg)",
        }}
      >
        <Row
          style={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col
            xl={{ span: 6 }}
            md={{ span: 9 }}
            sm={{ span: 12 }}
            style={{
              justifyContent: "center",
              alignItems: "center",
              // flex: 1,
            }}
          >
            <Image
              width="100%"
              // style={{ width: "100%" }}
              src={process.env.PUBLIC_URL + "/images/404-error.png"}
            />
          </Col>
        </Row>
      </div>
    </BasePage>
  );
}

export default Page401;
