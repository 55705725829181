import React, { useState } from "react";
import { Margin, usePDF } from "react-to-pdf";
import { Card, Col, Image, Row, Typography } from "antd";
import dayjs from "dayjs";

const textStyle = {
  fontSize: 26,
  fontWeight: "bold",
};
var imgs = [
  "https://gw.alipayobjects.com/zos/antfincdn/LlvErxo8H9/photo-1503185912284-5271ff81b9a8.webp",
  "https://gw.alipayobjects.com/zos/antfincdn/cV16ZqzMjW/photo-1473091540282-9b846e7965e3.webp",
  "https://gw.alipayobjects.com/zos/antfincdn/x43I27A55%26/photo-1438109491414-7198515b166b.webp",
];
function CardPDF({ data, isPrint, setIsPrint, setIsModalOpen }) {
  const { toPDF, targetRef } = usePDF();

  if (isPrint) {
    setTimeout(() => {
      toPDF({
        filename: data.card_uid + "_" + data.trans_time,
        page: { margin: Margin.MEDIUM },
      });
      setIsPrint(false);
      setIsModalOpen(false);
    }, 1000);
    //
  }
  // console.log(data);
  return (
    <div ref={targetRef}>
      <Card
        hoverable
        style={{
          width: "100%",
        }}
        title={
          <Typography.Title
            style={{ display: "flex", justifyContent: "center" }}
          >
            PORTAL LOCK REPORT
          </Typography.Title>
        }
        cover={
          <Image.PreviewGroup
            fallback={
              process.env.PUBLIC_URL + "/images/Image_not_available.png"
            }
            items={data.image_attach}
          >
            {isPrint ? (
              <Image.PreviewGroup
                fallback={
                  process.env.PUBLIC_URL + "/images/Image_not_available.png"
                }
              >
                {data.image_attach.length > 0 ? (
                  data.image_attach.map((data, idx) => {
                    <Image
                      key={idx}
                      src={data}
                      fallback={
                        process.env.PUBLIC_URL +
                        "/images/Image_not_available.png"
                      }
                    />;
                  })
                ) : (
                  <Image
                    key="singel-image"
                    src={data.image_path}
                    fallback={
                      process.env.PUBLIC_URL + "/images/Image_not_available.png"
                    }
                  />
                )}
              </Image.PreviewGroup>
            ) : (
              <Image
                width={"100%"}
                src={data.image_path}
                fallback={
                  process.env.PUBLIC_URL + "/images/Image_not_available.png"
                }
              />
            )}
          </Image.PreviewGroup>
        }
      >
        <Row gutter={30}>
          <Col span={12}>
            <Typography.Text style={textStyle}>TIME</Typography.Text>
          </Col>
          <Col span={12}>
            <Typography.Text style={textStyle}>
              {data.trans_time}
            </Typography.Text>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={12}>
            <Typography.Text style={textStyle}>GATE NAME</Typography.Text>
          </Col>
          <Col span={12}>
            <Typography.Text style={textStyle}>
              {data.gate_name}
            </Typography.Text>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={12}>
            <Typography.Text style={textStyle}>
              TRANSACTION TYPE
            </Typography.Text>
          </Col>
          <Col span={12}>
            <Typography.Text style={textStyle}>
              {data.trans_type}
            </Typography.Text>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={12}>
            <Typography.Text style={textStyle}>CARD ID</Typography.Text>
          </Col>
          <Col span={12}>
            <Typography.Text style={textStyle}>{data.card_uid}</Typography.Text>
          </Col>
        </Row>

        <Row gutter={30}>
          <Col span={12}>
            <Typography.Text style={textStyle}>OWNER NAME</Typography.Text>
          </Col>
          <Col span={12}>
            <Typography.Text style={textStyle}>
              {data.owner_name}
            </Typography.Text>
          </Col>
        </Row>

        <Row gutter={30}>
          <Col span={12}>
            <Typography.Text style={textStyle}>PHONE NUMBER</Typography.Text>
          </Col>
          <Col span={12}>
            <Typography.Text style={textStyle}>{data.phone}</Typography.Text>
          </Col>
        </Row>

        <Row gutter={30}>
          <Col span={12}>
            <Typography.Text style={textStyle}>LICENSE PLATE</Typography.Text>
          </Col>
          <Col span={12}>
            <Typography.Text style={textStyle}>{data.license}</Typography.Text>
          </Col>
        </Row>

        <Row gutter={30}>
          <Col span={12}>
            <Typography.Text style={textStyle}>OWNER ADDRESS</Typography.Text>
          </Col>
          <Col span={12}>
            <Typography.Text style={textStyle}>
              {data.owner_address}
            </Typography.Text>
          </Col>
        </Row>

        {/* <Row gutter={30}>
          <Col span={12}>
            <Typography.Text style={textStyle}>
              OWNER BLOCK NUMBER
            </Typography.Text>
          </Col>
          <Col span={12}>
            <Typography.Text style={textStyle}>
              {data.owner_block_number}
            </Typography.Text>
          </Col>
        </Row> */}

        <Row gutter={30}>
          <Col span={12}>
            <Typography.Text style={textStyle}>
              OWNER EXPIRED DATE
            </Typography.Text>
          </Col>
          <Col span={12}>
            <Typography.Text style={textStyle}>
              {dayjs.unix(data.owner_expired_date).format("YYYY-MM-DD")}
            </Typography.Text>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default CardPDF;
