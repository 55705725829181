import { SearchOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import React, { useRef, useState, useEffect } from "react";
import Highlighter from "react-highlight-words";
import {
  Button,
  Input,
  Space,
  Table,
  Switch,
  Row,
  Modal,
  Popconfirm,
  notification,
} from "antd";
import axios from "axios";
import config from "../../config/global";
import FormUserRegistration from "./FormUserRegistration";
import FormUserEdit from "./FormUserEdit";
import { ApiClient } from "../../api/ApiClient";

function TableUser() {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [modalUserRegistration, setModalUserRegistration] = useState(false);
  const [isModalUserEdit, setIsModalUserEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [allUser, setAllUser] = useState([]);
  const [dataUser, setDataUser] = useState({});
  const searchInput = useRef(null);

  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type, message, description) => {
    // console.log(type, message, description);
    api[type]({
      message: message,
      description: description,
    });
  };
  const removeJsonObject = (array, property, value) => {
    return array.filter((item) => item[property] === value);
  };

  const getUserList = async () => {
    ApiClient()
      .get(`/users?detail=1`)
      .then((res) => {
        var tmp = [];
        var dt = removeJsonObject(
          res.data,
          "rid",
          "2ba9dcf4-06e5-4a98-a5d3-907027bf1ee7"
        );
        dt?.forEach((element) => {
          if (localStorage.getItem("sid") == element.sid) {
            tmp.push(element);
          }
        });
        if (localStorage.getItem("credent") == 4) {
          setAllUser(dt);
        } else {
          setAllUser(tmp);
        }
        setIsLoading(false);
        // console.log(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    // await axios
    //   .get(`${config.BASEURL}/users?detail=1&refresh=true`, config.HEADERS)
    //   .then((res) => {
    //     setAllUser(res.data.data);
    //     setIsLoading(false);
    //     // console.log(res.data.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  const updateUserStatus = async (data) => {
    // console.log(data);
    ApiClient()
      .patch(`/users/${data.uid}`, {
        status: Number(!data.status),
      })
      .then((res) => {
        openNotificationWithIcon("success", "Update Status", res.message);
        setTimeout(getUserList, 1000);
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          "Update Status",
          err.response.data.message
        );
      });
    // await axios
    //   .patch(
    //     `${config.BASEURL}/users/${data.uid}`,
    //     {
    //       status: Number(!data.status),
    //     },
    //     config.HEADERS
    //   )
    //   .then((res) => {
    //     openNotificationWithIcon("success", "Update Status", res.data.message);
    //     getUserList();
    //   })
    //   .catch((err) => {
    //     openNotificationWithIcon(
    //       "error",
    //       "Update Status",
    //       err.response.data.message
    //     );
    //   });
  };

  const deleteUser = async (data) => {
    ApiClient()
      .del(`/users/${data.uid}`)
      .then((res) => {
        openNotificationWithIcon("success", "Delete User", res.message);
        getUserList();
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          "Delete User",
          err.response.data.message
        );
      });
    // await axios
    //   .delete(`${config.BASEURL}/users/${data.uid}`, config.HEADERS)
    //   .then((res) => {
    //     openNotificationWithIcon("success", "Delete User", res.data.message);
    //     getUserList();
    //   })
    //   .catch((err) => {
    //     openNotificationWithIcon(
    //       "error",
    //       "Delete User",
    //       err.response.data.message
    //     );
    //   });
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#DAFFFB" : "white",
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    // {
    //   title: "Timestamp",
    //   dataIndex: "timestamp",
    //   key: "timestamp",
    //   width: "20%",
    //   ...getColumnSearchProps("timestamp"),
    //   sorter: (a, b) => a.timestamp.length - b.timestamp.length,
    //   sortDirections: ["descend", "ascend"],
    // },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      width: "150px",
      ...getColumnSearchProps("username"),
      sorter: (a, b) => {
        return a.ID - b.ID;
      },
      fixed: "left",
      defaultSortOrder: "ascend",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      width: "200px",
      ...getColumnSearchProps("phone"),
    },
    {
      title: "E-mail",
      dataIndex: "email",
      key: "email",
      width: "200px",
      ...getColumnSearchProps("email"),
    },

    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: "200px",
      ...getColumnSearchProps("address"),
    },
    {
      title: "Edit",
      key: "edit",
      align: "center",
      width: "150px",
      render: (_, record) => {
        // console.log(record);
        return (
          <Space size="middle">
            {/* <Button
              type="primary"
              onClick={() => {
                setDataUser(record);
                setIsModalUserEdit(true);
              }}
              // style={{
              //   color: "red",
              // }}
            >
              Edit
            </Button> */}

            <button
              className="edit-button"
              onClick={() => {
                setDataUser(record);
                setIsModalUserEdit(true);
              }}
            >
              EDIT
            </button>
          </Space>
        );
      },
    },
    {
      title: "Status",
      key: "status",
      align: "center",
      width: "150px",
      render: (_, record) => {
        // console.log(record);
        return (
          <Space size="middle">
            <Popconfirm
              title="Update status user"
              description="Are you sure to update this user?"
              icon={<QuestionCircleOutlined style={{ color: "blue" }} />}
              onConfirm={() => {
                updateUserStatus(record);
                // deleteSite(record);
              }}
            >
              <Switch defaultChecked checked={record.status} />
            </Popconfirm>
          </Space>
        );
      },
    },
    {
      title: "Delete",
      key: "delete",
      align: "center",
      width: "150px",
      render: (_, record) => {
        // console.log(record);
        return (
          <Space size="middle">
            <Popconfirm
              title="Delete user"
              description="Are you sure to delete this user?"
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              onConfirm={() => {
                // updateUserStatus(record);
                deleteUser(record);
              }}
            >
              <button className="delete-button">DELETE</button>
              {/* <Button danger>Delete</Button> */}
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
  useEffect(() => {
    setIsLoading(true);
    getUserList();

    return () => getUserList();
    //
  }, []);
  return (
    // <div style={{ padding: "15px" }}>
    <>
      {contextHolder}
      <Row
        style={{
          display: "flow-root",
          paddingLeft: "15px",
          paddingRight: "15px",
        }}
      >
        <Row
          align="middle"
          style={{ flexDirection: "row-reverse", paddingBottom: "15px" }}
        >
          <button
            className="add-button"
            onClick={() => {
              setTimeout(() => {
                setModalUserRegistration(true);
              }, 300);
            }}
          >
            ADD USER
          </button>
          {/* <Button
            type="primary"
            onClick={() => {
              setModalUserRegistration(true);
            }}
          >
            ADD USER
          </Button> */}
        </Row>
        <Row style={{ display: "flow-root" }}>
          <Table
            columns={columns}
            dataSource={allUser}
            rowKey={(record) => {
              return record.uid;
            }}
            scroll={{
              x: 1300,
            }}
            pagination={{
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} users`,
              onChange: (e) => {
                // console.log(e);
              },
            }}
            loading={isLoading}
          />
        </Row>

        <FormUserRegistration
          getUserList={getUserList}
          openNotificationWithIcon={openNotificationWithIcon}
          modalUserRegistration={modalUserRegistration}
          setModalUserRegistration={setModalUserRegistration}
        />
        <FormUserEdit
          data={dataUser}
          openNotificationWithIcon={openNotificationWithIcon}
          isModalUserEdit={isModalUserEdit}
          setIsModalUserEdit={setIsModalUserEdit}
          getUserList={getUserList}
        />
      </Row>
    </>
    // </div>
  );
}
export default TableUser;
