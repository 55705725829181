import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Row, Typography, Avatar, Divider, Skeleton } from "antd";
import { BulbFilled, FireFilled, HeartFilled } from "@ant-design/icons";
import { Context as DataContext } from "../../context/DataContext";
import axios from "axios";
import config from "../../config/global";
import "../../App.css";
import { ApiClient } from "../../api/ApiClient";
const { Meta } = Card;
function Cards({ socketEvent }) {
  const [isLoading, setIsLoading] = useState(false);
  const [siteHealth, setSiteHealth] = useState([]);

  const getHealth = async () => {
    ApiClient()
      .get("/report/health?limit=50")
      .then((res) => {
        // console.log(res.data.data);
        setSiteHealth(res.data);
        // console.log(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSocket = (data) => {
    try {
      const newData = JSON.parse(data);

      if (newData.msg_type === "health") {
        setSiteHealth(newData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (socketEvent !== "") {
      handleSocket(socketEvent);
    }
  }, [socketEvent]);

  useEffect(() => {
    setIsLoading(true);
    getHealth();
    return () => getHealth();
  }, []);

  //   console.log(data.length);
  const allSite = [
    {
      key: 1,
      title: "Portalock Headquarter",
      server: 1,
      stat_all: 1,
      gate_in: {
        camera: 1,
        esp: 1,
      },
      gate_out: {
        camera: 1,
        esp: 1,
      },
    },
    {
      key: 2,
      title: "Skypiea",
      server: 1,
      stat_all: 2,
      gate_in: {
        camera: 0,
        esp: 1,
      },
      gate_out: {
        camera: 1,
        esp: 0,
      },
    },
    {
      key: 3,
      title: "TIGARESI 56",
      server: 0,
      stat_all: 3,
      gate_in: {
        camera: 0,
        esp: 0,
      },
      gate_out: {
        camera: 0,
        esp: 1,
      },
    },
    {
      key: 4,
      title: "TIGARESI 56",
      server: 1,
      stat_all: 0,
      gate_in: {
        camera: 1,
        esp: 1,
      },
      gate_out: {
        camera: 1,
        esp: 1,
      },
    },
    {
      key: 5,
      title: "TIGARESI 56",
      server: 1,
      stat_all: 1,
      gate_in: {
        camera: 1,
        esp: 1,
      },
      gate_out: {
        camera: 1,
        esp: 1,
      },
    },
    {
      key: 6,
      title: "TIGARESI 56",
      server: 1,
      stat_all: 1,
      gate_in: {
        camera: 1,
        esp: 1,
      },
      gate_out: {
        camera: 1,
        esp: 1,
      },
    },
    {
      key: 7,
      title: "TIGARESI 56",
      server: 1,
      stat_all: 1,
      gate_in: {
        camera: 1,
        esp: 1,
      },
      gate_out: {
        camera: 1,
        esp: 1,
      },
    },
    {
      key: 8,
      title: "TIGARESI 56",
      server: 1,
      stat_all: 1,
      gate_in: {
        camera: 1,
        esp: 1,
      },
      gate_out: {
        camera: 1,
        esp: 1,
      },
    },
    {
      key: 9,
      title: "TIGARESI 56",
      server: 1,
      stat_all: 1,
      gate_in: {
        camera: 1,
        esp: 1,
      },
      gate_out: {
        camera: 1,
        esp: 1,
      },
    },
    {
      key: 10,
      title: "TIGARESI 56",
      server: 1,
      stat_all: 1,
      gate_in: {
        camera: 1,
        esp: 1,
      },
      gate_out: {
        camera: 1,
        esp: 1,
      },
    },
    {
      key: 11,
      title: "TIGARESI 56",
      server: 1,
      stat_all: 1,
      gate_in: {
        camera: 1,
        esp: 1,
      },
      gate_out: {
        camera: 1,
        esp: 1,
      },
    },
    {
      key: 12,
      title: "TIGARESI 56",
      server: 1,
      stat_all: 1,
      gate_in: {
        camera: 1,
        esp: 1,
      },
      gate_out: {
        camera: 1,
        esp: 1,
      },
    },
  ];
  return (
    <Row
      style={{
        // backgroundColor: "red",
        // paddingLeft: "5px ",
        paddingTop: "5px",
        margin: "5px",
        // maxWidth: "800px",
        // display: "flex",
        // justifyContent: "space-between",
        // paddingRight: "15px",
      }}
      key={siteHealth.length}
      gutter={[30, 30]}
    >
      {siteHealth?.map((dt, idx) => {
        return (
          <Col key={dt.id} xxl={4} xl={6} lg={6} md={12} sm={16} xs={20}>
            <Skeleton active loading={isLoading}>
              <Card
                key={dt.id}
                title={
                  <Row gutter={30}>
                    <Col span={18}>
                      <Typography.Text className="card-text-title">
                        {dt.site_name}
                      </Typography.Text>
                    </Col>
                    <Col span={2}>
                      <HeartFilled
                        className={dt.status === 1 ? "" : "blink"}
                        style={{
                          textShadow: "revert-layer",
                          fontSize: "20px",
                          color:
                            dt.status === 1
                              ? "green"
                              : dt.status === 2
                              ? "yellow"
                              : "red",
                        }}
                      />
                    </Col>
                  </Row>
                }
                headStyle={{
                  backgroundColor: "#04364A",
                  color: "white",
                }}
                bordered={false}
                style={{
                  width: "250px",
                  backgroundColor: "#DAFFFB",
                  // minWidth: "250px",

                  // color: "green",
                  // flexDirection: "row",
                }}
              >
                <Row
                  align="middle"
                  style={{ paddingBottom: "10px", justifyContent: "center" }}
                >
                  <Typography.Text className="card-text-body">
                    SERVER STATUS
                  </Typography.Text>
                  <BulbFilled
                    style={{
                      paddingLeft: "15px",
                      color: dt.status === 1 ? "green" : "red",
                    }}
                  />
                </Row>

                <Row
                  align="stretch"
                  gutter={30}
                  style={{ paddingLeft: "20px" }}
                >
                  {dt.gates.map((gates, idx) => {
                    return (
                      <Col
                        key={idx}
                        span={12}
                        style={{
                          width: "50%",
                          padding: "0px",
                          // borderRight: "1px solid black",
                        }}
                      >
                        <Typography.Text className="card-text-body">
                          {gates.gate_name}
                        </Typography.Text>

                        <Row style={{ display: "flex", paddingTop: "15px" }}>
                          <Typography.Text className="card-text-body">
                            Controller
                          </Typography.Text>
                          <BulbFilled
                            style={{
                              paddingLeft: "10px",
                              // alignSelf: "flex-end",
                              color: gates.status === 1 ? "green" : "red",
                            }}
                          />
                        </Row>
                      </Col>
                    );
                  })}
                  {/* <Col
                    span={11}
                    style={{
                      width: "50%",
                      padding: "0px",
                      borderRight: "1px solid black",
                    }}
                  >
                    <Typography.Text className="card-text-body">
                      {dt.gates[0].gate_name}
                    </Typography.Text>

                    <Row style={{ display: "flex", paddingTop: "15px" }}>
                      <Typography.Text className="card-text-body">
                        Controller
                      </Typography.Text>
                      <BulbFilled
                        style={{
                          paddingLeft: "10px",
                          // alignSelf: "flex-end",
                          color: dt.gate_in.esp === 1 ? "green" : "red",
                        }}
                      />
                    </Row>

                    <Row align="middle">
                      <Typography.Text className="card-text-body">
                        Camera
                      </Typography.Text>
                      <BulbFilled
                        style={{
                          paddingLeft: "25px",
                          // alignSelf: "flex-end",
                          // color: dt.gate_in.camera === 1 ? "green" : "red",
                        }}
                      />
                    </Row>
                  </Col>

                  <Col
                    span={13}
                    style={{
                      width: "50%",
                      paddingLeft: "10px",
                    }}
                  >
                    <Typography.Text className="card-text-body">
                      GATE OUT
                    </Typography.Text>

                    <Row style={{ display: "flex", paddingTop: "15px" }}>
                      <Typography.Text className="card-text-body">
                        Controller
                      </Typography.Text>
                      <BulbFilled
                        style={{
                          paddingLeft: "10px",
                          // alignSelf: "flex-end",
                          // color: dt.gate_out.esp === 1 ? "green" : "red",
                        }}
                      />
                    </Row>
                    <Row>
                      <Typography.Text className="card-text-body">
                        Camera
                      </Typography.Text>
                      <BulbFilled
                        style={{
                          paddingLeft: "25px",
                          // alignSelf: "flex-end",
                          // color: dt.gate_out.camera === 1 ? "green" : "red",
                        }}
                      />
                    </Row>
                  </Col> */}
                </Row>
              </Card>
            </Skeleton>
          </Col>
        );
      })}
    </Row>
  );
}

export default Cards;
