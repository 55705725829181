import React, { useState } from "react";
import TableUserLog from "../../components/user/TableUserLog";
import TableAdminLog from "../../components/admin/TableAdminLog";

function LogPage({ socketEvent }) {
  const role = localStorage.getItem("credent");
  const [newDataLog, setNewDataLog] = useState();

  return (
    <div className="load-content">
      {role !== "1" ? (
        <TableAdminLog socketEvent={socketEvent} />
      ) : (
        <TableUserLog socketEvent={socketEvent} />
      )}
    </div>
  );
}

export default LogPage;
