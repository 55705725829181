import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  Switch,
  Table,
  Space,
  Popconfirm,
} from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

function FormSiteRegistration({
  setAddSite,
  setButtonModalDisabled,
  setClearForm,
  clearForm,
}) {
  const [gateList, setGateList] = useState([]);
  const [lockInput, setLockInput] = useState(false);

  const [form] = Form.useForm();
  // const [clearForm, setClearForm] = useState(true);

  const columns = [
    {
      title: "Gate Name",
      dataIndex: "gate_name",
      key: "gate_name",
      width: "150px",
    },
    {
      title: "Gate Type",
      dataIndex: "gate_type",
      key: "gate_type",

      width: "150px",
    },
    {
      title: "Status",
      key: "status",
      align: "center",

      width: "100px",
      render: (_, record) => {
        // console.log(record);
        return (
          <Space size="middle">
            <Switch
              defaultChecked
              checked={record.status}
              onChange={() => {
                // updateStatus(record);
              }}
            />
          </Space>
        );
      },
    },
    {
      title: "Delete",
      key: "delete",
      align: "center",

      width: "100px",
      render: (_, record) => {
        // console.log(record);
        return (
          <Space size="middle">
            <Popconfirm
              title="Delete the card"
              description="Are you sure to delete this card?"
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              onConfirm={() => {
                var array = [...gateList]; // make a separate copy of the array
                var index = array.map((v) => v.id).indexOf(record.id);

                if (index !== -1) {
                  array.splice(index, 1);
                  setGateList(array);
                }
              }}
            >
              <Button danger>Delete</Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const addGate = async (val) => {
    const gate = {
      id: Math.floor(Math.random() * 1000) + 1,
      gate_name: val.gate_name,
      gate_type: val.gate_type,
      status: 1,
    };
    if (gateList.length === 0) {
      setGateList([gate]);
    } else {
      setGateList((prev) => [...prev, gate]);
    }
    // console.log(form.getFieldValue());

    setButtonModalDisabled(false);
    setLockInput(true);
  };
  useEffect(() => {
    if (clearForm) {
      form.resetFields();
      setGateList([]);
      setLockInput(false);
      setClearForm(false);
    }
  }, [clearForm]);

  useEffect(() => {
    setAddSite({ site: form.getFieldValue(), gate: gateList });
  }, [gateList]);

  return (
    <div>
      <Form
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 14,
        }}
        form={form}
        // onFieldsChange={() =>
        //   setButtonModalDisabled(
        //     form.getFieldError().some((field) => field.errors.length > 0)
        //   )
        // }
        // onFinish={addGate}
        layout="horizontal"
        style={{ paddingRight: "15px", paddingLeft: "15px" }}
      >
        <Form.Item
          label="Site Name"
          name="site_name"
          rules={[
            {
              required: true,
              message: "Site Name is required",
            },
          ]}
        >
          <Input disabled={lockInput} />
        </Form.Item>
        <Form.Item
          label="Site Address"
          name="address"
          rules={[
            {
              required: true,
              message: "Site Address is required",
            },
          ]}
        >
          <Input disabled={lockInput} />
        </Form.Item>
        <Form.Item
          label="City"
          name="city"
          rules={[
            {
              required: true,
              message: "city Address is required",
            },
          ]}
        >
          <Input disabled={lockInput} />
        </Form.Item>
        <Form.Item
          label="Urban Village"
          name="urban_village"
          rules={[
            {
              required: true,
              message: "Urban Village is required",
            },
          ]}
        >
          <Input disabled={lockInput} />
        </Form.Item>
        <Form.Item
          label="District"
          name="district"
          rules={[
            {
              required: true,
              message: "District is required",
            },
          ]}
        >
          <Input disabled={lockInput} />
        </Form.Item>
        <Form.Item
          label="Regency"
          name="regency"
          rules={[
            {
              required: true,
              message: "Regency is required",
            },
          ]}
        >
          <Input disabled={lockInput} />
        </Form.Item>
        <Form.Item
          label="Province"
          name="province"
          rules={[
            {
              required: true,
              message: "Province is required",
            },
          ]}
        >
          <Input disabled={lockInput} />
        </Form.Item>

        <Form.Item
          label="Gate Name"
          name="gate_name"
          rules={[{ required: true, message: "Gate Name is required" }]}
        >
          <Input style={{ maxWidth: 300 }}></Input>
        </Form.Item>

        <Form.Item
          initialValue="gate in"
          label="Gate Type"

          // style={{ justifyContent: "space-between" }}
        >
          <Space size="large">
            <Form.Item
              name="gate_type"
              rules={[{ required: true, message: "Gate Type is required" }]}
              noStyle
            >
              <Select
                style={{ width: 150 }}
                options={[
                  { value: "IN", label: "GATE IN" },
                  { value: "OUT", label: "GATE OUT" },
                ]}
              />
            </Form.Item>

            <button
              className="add-button"
              onClick={() => {
                addGate(form.getFieldsValue());
              }}
            >
              ADD GATE
            </button>
            {/* <Button type="primary" htmlType="submit">
              Add Gate
            </Button> */}
          </Space>
        </Form.Item>
      </Form>
      <Table
        pagination={{ pageSize: 4 }}
        columns={columns}
        dataSource={gateList}
        rowKey={(record) => {
          return record.id;
        }}
      />
    </div>
  );
}

export default FormSiteRegistration;
