import React from "react";

import { Navigate, useLocation } from "react-router-dom";

function ProtectedRoute({ children }) {
  const location = useLocation();
  // const cekTokenTime = () => {
  //   axios
  //     .get(config.BASEURL + "/token-test", {
  //       headers: { "x-access-token": localStorage.getItem("token") },
  //     })
  //     .then((res) => {
  //       console.log(res.status);
  //     })
  //     .catch((err) => {
  //       localStorage.removeItem("token");

  //       return <Navigate to="/login" state={{ from: location }} replace />;
  //     });
  // };
  // useEffect(() => {
  //   cekTokenTime();
  // }, []);

  if (localStorage.getItem("credent") === "1") {
    const userNav = ["/", "/log", "/user-info", "/ticket-list"];

    if (!userNav.includes(location.pathname)) {
      return <Navigate to="/" state={{ from: location }} replace />;
    }
  } else if (
    localStorage.getItem("credent") === "2" &&
    location.pathname !== "/account-list"
  ) {
    return <Navigate to="/account-list" state={{ from: location }} replace />;
  } else if (localStorage.getItem("credent") === "3") {
    const userNav = ["/user-list", "/account-list"];

    if (!userNav.includes(location.pathname)) {
      console.log("first here");
      return <Navigate to="/user-list" state={{ from: location }} replace />;
    }
  }

  if (!localStorage.getItem("token")) {
    return <Navigate to="/login" />;
  }

  return children;
}

export default ProtectedRoute;
