import React from "react";
import TableCardListAdmin from "../../components/admin/TableCardListAdmin";

function CardListPage() {
  return (
    // <BasePage>
    //   <Sidebar title="CARD LIST USER" path="/card-list">
    //   </Sidebar>
    // </BasePage>
    <div className="load-content">
      <TableCardListAdmin />
    </div>
  );
}

export default CardListPage;
