import { SearchOutlined } from "@ant-design/icons";
import React, { useRef, useState, useEffect } from "react";
import Highlighter from "react-highlight-words";
import { Button, Input, Space, Table, Tag, Modal, Image } from "antd";
import moment from "moment/moment";
import { Margin, usePDF } from "react-to-pdf";
import CardPDF from "../shared/CardPDF";
import { ApiClient } from "../../api/ApiClient";

function TableUserLog({ socketEvent }) {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [record, setRecord] = useState({});

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isPrint, setIsPrint] = useState(false);
  const [visibleImage, setVisibleImage] = useState(false);

  const [dataTable, setDataTable] = useState([]);
  const searchInput = useRef(null);

  const localSID = localStorage.getItem("sid");
  const { toPDF, targetRef } = usePDF();

  const getLogLimit = () => {
    ApiClient()
      .get(`/report/trans?field=sid&data=${localSID}&limit=1000`)
      .then((res) => {
        setIsLoading(false);
        setDataTable(res.data);
        // console.log(res.data);
      })
      .catch((err) => {
        // console.log(err);
      });
    // await axios
    //   .get(
    //     `${config.BASEURL}/report/trans?field=sid&data=${localSID}`,
    //     config.HEADERS
    //   )
    //   .then((res) => {
    //     setIsLoading(false);
    //     setDataTable(res.data.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };
  const getLogFull = () => {
    ApiClient()
      .get(`/report/trans?field=sid&data=${localSID}`)
      .then((res) => {
        // setIsLoading(false);
        setDataTable(res.data);
        // console.log(res.data);
      })
      .catch((err) => {
        // console.log(err);
      });
    // await axios
    //   .get(
    //     `${config.BASEURL}/report/trans?field=sid&data=${localSID}`,
    //     config.HEADERS
    //   )
    //   .then((res) => {
    //     setIsLoading(false);
    //     setDataTable(res.data.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };
  const getLogAppend = () => {
    ApiClient()
      .get(`/report/trans?field=sid&data=${localSID}`)
      .then((res) => {
        setIsLoading(false);
        setDataTable((newData) => [res.data, ...newData]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSocket = (data) => {
    try {
      const newData = JSON.parse(data);
      if (newData.msg_type === "trans") {
        if (dataTable[0].id === newData.trans.id) {
        } else {
          // console.log(newData.trans.id);
          setDataTable((oldData) => [...oldData, newData.trans]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const hanldePrint = () => {
    setIsPrint(true);
    // setIsModalOpen(false);

    // toPDF({
    //   filename: record.card_uid + "_" + record.trans_time,
    //   page: { margin: Margin.MEDIUM },
    // });

    // ReactDOM.render(<PDFView />, document.getElementById("root"));
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({
              closeDropdown: false,
            });
            setSearchText(e.target.value ? e.target.value : "");
            setSearchedColumn(dataIndex);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#DAFFFB" : "white",
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),

    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Timestamp",
      dataIndex: "trans_time",
      key: "trans_time",
      width: "200px",
      ...getColumnSearchProps("trans_time"),
      fixed: "left",

      sorter: (a, b) => a.id - b.id,
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "descend",
    },
    {
      title: "Card ID",
      dataIndex: "card_uid",
      key: "card_uid",
      width: "130px",
      fixed: "left",
      ...getColumnSearchProps("card_uid"),
    },
    {
      title: "Owner Name",
      dataIndex: "owner_name",
      key: "owner_name",
      fixed: "left",
      width: "180px",
      ...getColumnSearchProps("owner_name"),
    },
    {
      title: "License",
      dataIndex: "license",
      key: "license",
      width: "100px",
      // align: "center",
      fixed: "left",
      ...getColumnSearchProps("license"),
    },
    {
      title: "Address",
      dataIndex: "owner_address",
      key: "owner_address",
      width: "450px",
      align: "center",
      // fixed: "left",
      ...getColumnSearchProps("owner_address"),
    },

    // {
    //   title: "Owner Block Number",
    //   dataIndex: "owner_block_number",
    //   key: "owner_block_number",
    //   width: "200px",
    //   align: "center",
    //   // fixed: "left",
    //   ...getColumnSearchProps("owner_block_number"),
    // },
    // {
    //   title: "Owner Balance",
    //   dataIndex: "owner_balance",
    //   key: "owner_balance",
    //   width: "200px",
    //   align: "center",
    //   // fixed: "left",
    //   ...getColumnSearchProps("owner_balance"),
    // },
    // {
    //   title: "Owner Expired Date",
    //   dataIndex: "owner_expired_date",
    //   key: "owner_expired_date",
    //   width: "200px",
    //   align: "center",
    //   // fixed: "left",
    //   ...getColumnSearchProps("owner_expired_date"),
    // },
    {
      title: "Site ID",
      dataIndex: "site_id",
      key: "site_id",
      width: "100px",
      align: "center",
      // fixed: "left",
      ...getColumnSearchProps("site_id"),
    },

    {
      title: "Gate ID",
      dataIndex: "gate_id",
      key: "gate_id",
      width: "100px",
      align: "center",
      // fixed: "left",
      ...getColumnSearchProps("gate_id"),
    },

    {
      title: "Method",
      dataIndex: "trans_type",
      key: "trans_type",
      width: "100px",
      fixed: "right",
      align: "center",
      ...getColumnSearchProps("trans_type"),
      render: (_, record) => {
        return (
          <Tag
            color={
              record.trans_type === "OTP"
                ? "gold-inverse"
                : record.trans_type === "QR"
                ? "green-inverse"
                : record.trans_type === "CARD"
                ? "blue-inverse"
                : "red-inverse"
            }
          >
            {record.trans_type}
          </Tag>
        );
      },
    },
    {
      title: "Event",
      dataIndex: "gate_type",
      key: "gate_type",
      width: "100px",
      fixed: "right",
      align: "center",
      ...getColumnSearchProps("gate_type"),
      render: (_, record) => {
        return (
          <Tag
            color={record.gate_type === "IN" ? "blue-inverse" : "red-inverse"}
          >
            {record.gate_type}
          </Tag>
        );
      },
    },
    {
      title: "Image",
      dataIndex: "image_path",
      key: "image_path",
      width: "150px",
      align: "center",
      fixed: "right",

      render: (_, record) => {
        // console.log(record.image_path);
        return (
          <Space size="middle">
            {/* <Button
              type="link"
              style={{ borderColor: "blue" }}
              onClick={() => {
                // setRecordData(record.im);
                setImagepath(record.image_path);
                setIsModalOpen(true);
                // console.log(record.image_path);
              }}
            >
              View Image
            </Button> */}
            <button
              className="detail-button"
              onClick={() => {
                // setRecordData(record.im);
                setRecord(record);
                setIsModalOpen(true);
                // console.log(record.image_path);
              }}
            >
              VIEW
            </button>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    // setIsLoading(true);
    getLogLimit();
    getLogFull();
    return () => {
      getLogLimit();
      getLogFull();
    };
  }, []);
  useEffect(() => {
    if (socketEvent !== "") {
      handleSocket(socketEvent);
    }
  }, [socketEvent]);

  return (
    <div style={{ padding: "15px" }}>
      {/* <Row align="middle" style={{ paddingBottom: "15px" }}>
        <Typography.Text style={{ paddingRight: "10px" }}>
          SELECT LIST :
        </Typography.Text>

        <Select options={allSite} style={{ width: "50%" }} />
      </Row> */}

      <Table
        columns={columns}
        dataSource={dataTable}
        rowKey={(record) => {
          return record.id;
        }}
        scroll={{
          x: 1300,
        }}
        pagination={{
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} datas`,
          onChange: (e) => {
            if (e === 20) {
              // getLogAppend();
            }
            // console.log(e);
          },
        }}
        loading={isLoading}
        summary={() => {
          <Table.Summary fixed="top" />;
        }}
        sticky={{
          offsetHeader: 50,
        }}
      />
      <Modal
        // title="Image"
        open={isModalOpen}
        width={1340}
        align="center"
        onOk={() => {
          setIsModalOpen(false);
        }}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        footer={[
          <Space key="spacebutton">
            {record.image_attach?.length > 0 ? (
              <button
                key="additional"
                className="save-button"
                onClick={() => {
                  // console.log(record);
                  setVisibleImage(true);
                }}
              >
                Additional Image
              </button>
            ) : (
              <></>
            )}
            <button
              key="printbutton"
              className="save-button"
              onClick={() => {
                hanldePrint(record);
              }}
            >
              PRINT
            </button>
            <button
              key="closebutton"
              className="cancel-button"
              onClick={() => {
                setIsModalOpen(false);
              }}
            >
              CLOSE
            </button>
          </Space>,
          // <Button
          //   key="print"
          //   type="primary"
          //   onClick={() => {
          //     hanldePrint(record);
          //   }}
          // >
          //   Print
          // </Button>,
          // <Button
          //   key="close"
          //   onClick={() => {
          //     setIsModalOpen(false);
          //   }}
          // >
          //   Close
          // </Button>,
          // <Button key="link" href="https://google.com" type="primary">
          //   Search on Google
          // </Button>,
        ]}
      >
        {/* <Image
          src={imagepath}
          // height={720}
          // width={1280}
          style={{
            height: "100%",
            width: "100%",
          }}
          preview={false}
          fallback={process.env.PUBLIC_URL + "/images/404-error.png"}
        /> */}
        {/* <div ref={targetRef}> */}
        <CardPDF
          data={record}
          isPrint={isPrint}
          setIsPrint={setIsPrint}
          setIsModalOpen={setIsModalOpen}
        />
        {/* </div> */}
      </Modal>
      <Image.PreviewGroup
        style={{
          display: "none",
        }}
        preview={{
          visible: visibleImage,
          scaleStep: 0.5,
          // src: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
          onVisibleChange: (value) => {
            setVisibleImage(value);
          },
        }}
        items={record.image_attach}
        fallback={process.env.PUBLIC_URL + "/images/Image_not_available.png"}
      />
    </div>
  );
}
export default TableUserLog;
